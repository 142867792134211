import { Pipe, PipeTransform } from '@angular/core';
import { momentDateFormat } from '../shared/variables/variables';
import {
  GetLanguage,
  GetPreferredDate,
} from '../shared/functions/shared-functions';

@Pipe({
  name: 'customDateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (value) {
      // need hijri format
      // if (GetLanguage() === 'ar') {
      //   return new Date(value).toLocaleDateString('ar-SA', {year: 'numeric', month: 'long', day: '2-digit'})
      // }
      return momentDateFormat(value);
    }
  }
}
