<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="vex-sidenav-toolbar__container">
    <div
      class="sidenav-toolbar flex-none flex items-center justify-content-center p-0"
    >
      <img
        *ngIf="!collapsed || (collapsedOpen$ | async); else small_logo"
        routerLink="/"
        [src]="imageUrl$ | async"
        alt="Logo"
        class="select-none flex-none cursor-pointer main-logo"
      />
      <ng-template #small_logo>
        <img
          routerLink="/"
          [src]="mainLogoSmall"
          alt="Logo"
          class="select-none flex-none cursor-pointer main-logo"
        />
      </ng-template>
      <!--      <h2 class="vex-sidenav-toolbar__headline flex-auto">{{ title$ | async }}</h2>-->
      <button
        (click)="toggleCollapse()"
        *ngIf="
          (showCollapsePin$ | async) && (!collapsed || (collapsedOpen$ | async))
        "
        class="align-items-end d-flex hidden justify-content-end p-0 lg:block collapse-btn"
        mat-icon-button
        type="button"
      >
        <mat-icon
          *ngIf="!collapsed"
          class="text-[0.875rem]"
          svgIcon="mat:radio_button_checked"
        ></mat-icon>
        <mat-icon
          *ngIf="collapsed"
          class="text-[0.875rem]"
          svgIcon="mat:radio_button_unchecked"
        ></mat-icon>
      </button>
    </div>

    <!--    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">-->
    <!--      <div class="vex-sidenav-search relative"-->
    <!--           (click)="openSearch()"-->
    <!--           matRipple-->
    <!--           matRippleColor="var(&#45;&#45;sidenav-item-ripple-color)">-->
    <!--        <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>-->
    <!--        <div class="flex-auto vex-sidenav-search__placeholder">Quick Search</div>-->
    <!--        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <app-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <sidenav-item
        (click)="checkChildren(item)"
        *ngFor="let item of items; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      ></sidenav-item>
    </div>

    <div
      class="vex-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none"
    >
      <!--      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none"/>-->
    </div>
  </app-scrollbar>

  <!--  <div *ngIf="userVisible$ | async"-->
  <!--       class="vex-sidenav-user__container flex-none">-->
  <!--    <div #userProfileMenuOriginRef-->
  <!--         (click)="openProfileMenu(userProfileMenuOriginRef)"-->
  <!--         [class.vex-sidenav-user&#45;&#45;open]="userMenuOpen$ | async"-->
  <!--         class="vex-sidenav-user flex items-center"-->
  <!--         matRipple-->
  <!--         matRippleColor="var(&#45;&#45;sidenav-item-ripple-color)">-->
  <!--      <img alt="User Avatar" class="vex-sidenav-user__image flex-none" src="{{userAvatar$ | async}}"/>-->
  <!--      <div class="vex-sidenav-user__content flex-auto">-->
  <!--        <div class="vex-sidenav-user__title">David Smith</div>-->
  <!--        <div class="vex-sidenav-user__subtitle">Product Manager</div>-->
  <!--      </div>-->
  <!--      <mat-icon class="vex-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
