<app-page-layout>
  <!--    <app-page-layout-header class="flex flex-col items-start">-->
  <!--      <div class="px-gutter w-full flex flex-col justify-between px-0">-->
  <!--        <div class="d-flex justify-content-between align-items-center pt-2">-->
  <!--          <app-breadcrumbs></app-breadcrumbs>-->
  <!--          <div *ngIf="(inList$ | async) && hasFilter"-->
  <!--               class="d-flex justify-content-end align-items-center rounded-full filter-title">-->
  <!--            <button [ngClass]="{'active-icon': list_type === 'table'}" *ngIf="hasGrid" (click)="toggleList('table')"-->
  <!--                    class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center align-items-center " color="primary"-->
  <!--                    mat-icon-button type="button">-->
  <!--              <mat-icon svgIcon="mat:table_rows"></mat-icon>-->
  <!--            </button>-->
  <!--            <button *ngIf="hasGrid" [ngClass]="{'active-icon': list_type === 'grid'}" (click)="toggleList('grid')"-->
  <!--                    class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center rounded-full align-items-center "-->
  <!--                    color="primary" mat-icon-button type="button">-->
  <!--              <mat-icon svgIcon="mat:grid_on"></mat-icon>-->
  <!--            </button>-->
  <!--            <button [ngClass]="{'active-icon': filterOpened}" (click)="toggleFilter()"-->
  <!--                    class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center rounded-full align-items-center "-->
  <!--                    color="primary" mat-icon-button type="button">-->
  <!--              <mat-icon *ngIf="!filterOpened" svgIcon="mat:filter_alt"-->
  <!--                        tooltip="{{'Open' | translate}} {{'Filter' | translate}}"></mat-icon>-->
  <!--              <mat-icon *ngIf="filterOpened" svgIcon="mat:filter_alt_off"-->
  <!--                        tooltip="{{'Open' | translate}} {{'Filter' | translate}}"></mat-icon>-->
  <!--            </button>-->
  <!--            <button *ngIf="downloadPdf" (click)="downloadAsPdf('pdf')"-->
  <!--                    class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center rounded-full align-items-center "-->
  <!--                    color="primary" mat-icon-button type="button">-->
  <!--              <mat-icon svgIcon="mat:picture_as_pdf"-->
  <!--                        tooltip="{{'Download' | translate}} {{'PDF' | translate}}"></mat-icon>-->
  <!--            </button>-->
  <!--            <button *ngIf="downloadExcel" (click)="downloadAsExcel('excel')"-->
  <!--                    class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center rounded-full align-items-center "-->
  <!--                    color="primary" mat-icon-button type="button">-->
  <!--              <i class="fa fa-file-excel-o" tooltip="{{'Download' | translate}} {{'Excel' | translate}}"></i>-->
  <!--            </button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </app-page-layout-header>-->
  <app-page-layout-content class="px-gutter">
    <div class="card mb-4">
      <ng-content></ng-content>
    </div>
  </app-page-layout-content>
</app-page-layout>
