import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../../../services/core.service';
import { secureStorage } from '../../../shared/functions/secure-storage';
import { GetLanguage } from '../../../shared/functions/shared-functions';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-noc-certificate',
  templateUrl: './noc-certificate.component.html',
  styleUrls: ['./noc-certificate.component.scss'],
})
export class NOCCertificateComponent implements OnInit {
  qrdata: string = null;
  qr_elementType: 'img' | 'url' | 'canvas' | 'svg' = null;
  level: 'L' | 'M' | 'Q' | 'H';
  scale = 1;
  qr_width = 256;
  printCertificatData: any;
  printAr: boolean;
  elementType = 'svg';
  value: any;
  format = 'CODE128';
  lineColor = '#000000';
  width = 1.7;
  height = 60;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 0;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  loading = false;
  logo =
    GetLanguage() === 'ar'
      ? '../../../../../assets/img/logos/arabic-logo-transparent.png'
      : '../../../../../assets/img/logos/arabic-logo-transparent.png';
  print_id;
  architectural = false;
  from_classification: any = false;
  membership_type;
  cert_id;

  constructor(
    private coreService: CoreService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.cert_id = secureStorage.decryptItem(
      this.activatedRoute.snapshot.queryParamMap
        .get('id')
        ?.replaceAll(' ', '+'),
    );
    this.from_classification = this.activatedRoute.snapshot.queryParamMap.get(
      'from_classification',
    );
    let link = ``;
    if (this.from_classification) {
      link = `KSEIssuedCertificate/GetAllKSEIssuedCertificateClassificationbyId?Id=${this.cert_id}`;
    } else {
      link = `KSEIssuedCertificate/GetAllKSEIssuedCertificatebyId?Id=${this.cert_id}`;
    }
    this.loading = true;
    this.coreService
      .getRequest(link)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        const item = res[0];
        if (item) {
          console.log('cert', item);
          this.handleTableObject(item);
          this.printAr = GetLanguage() === 'ar';
          this.value = '*' + this.printCertificatData?.id + '*';
          this.qrdata = `${
            environment.webUrl
          }certificate?qr=${secureStorage.encryptItem(
            this.printCertificatData?.qrcode,
          )}`;
          this.membership_type =
            this.printCertificatData.personMembership?.membershipType?.actionCode;
          this.print_id =
            this.printCertificatData?.kseIssuedCertType?.actionCode;
          console.log('print_id', this.print_id);
          this.architectural =
            this.printCertificatData?.certificate?.secCollgProfession
              ?.profession?.actionCode === 'architectural';
        }
      });
  }

  handleTableObject(item) {
    const date = item?.panelDate;
    const dt = moment(date, 'YYYY-MM-DD HH:mm:ss');
    item.testDay = moment(dt).locale('ar').format('dddd');
    item.startDate = item?.startDate
      ? moment(item?.startDate).format('DD/MM/YYYY')
      : '';
    item.endDate = item?.endDate
      ? moment(item?.endDate).format('DD/MM/YYYY')
      : '';
    item.arabicName =
      item.personData?.firstNameAr +
      ' ' +
      (item.personData?.secondNameAr !== '-'
        ? item.personData?.secondNameAr
        : '') +
      ' ' +
      (item.personData?.thirdNameAr !== '-'
        ? item.personData?.thirdNameAr
        : '') +
      ' ' +
      item.personData?.familyNameAr;
    item.englishName =
      item.personData?.firstNameEN +
      ' ' +
      (item.personData?.secondNameEN !== '-'
        ? item.personData?.secondNameEN
        : '') +
      ' ' +
      (item.personData?.thirdNameEN !== '-'
        ? item.personData?.thirdNameEN
        : '') +
      ' ' +
      item.personData?.familyNameEN;
    item.civilID = item.personData?.civilID;
    item.receiptNum = item.casher?.receiptNum;
    item.job = item?.personJob ? item?.personJob[0]?.newJobEntry : '';
    item.organization =
      GetLanguage() === 'ar'
        ? item.organization?.nameAr
        : item.organization?.nameEn;
    item.receiptDate = item.casher?.receiptDate
      ? moment(item.casher?.receiptDate).format('DD/MM/YYYY')
      : '';
    item.passportNum = item.personData?.passportNum;
    item.residencyEndDate = item.personData?.residencyEndDate
      ? moment(item.personData?.residencyEndDate)?.format('DD/MM/YYYY')
      : '';
    if (item.personMembership) {
      item.personMembership.expiryDate = item.personMembership?.expiryDate
        ? moment(item.personMembership?.expiryDate)?.format('DD/MM/YYYY')
        : '';
    }
    item.profession =
      GetLanguage() === 'ar'
        ? item?.certificate?.secCollgProfession?.profession?.nameAr
        : item?.certificate?.secCollgProfession?.profession?.nameEn;
    item.subProfession =
      GetLanguage() === 'ar'
        ? item?.certificate?.secCollgProfession?.subProfession?.nameAr
        : item?.certificate?.secCollgProfession?.subProfession?.nameEn;
    item.personStatusMinDate = item.personStatusMinDate
      ? moment(item.personStatusMinDate)?.format('DD/MM/YYYY')
      : '';
    item.personEvalMinDate = item.personEvalMinDate
      ? moment(item.personEvalMinDate)?.format('DD/MM/YYYY')
      : '';
    item.issueDate = item.issueDate
      ? moment(item.issueDate)?.format('DD/MM/YYYY')
      : '-';
    if (this.from_classification) {
      item.expireDate = item.expireDate
        ? moment(item.expireDate).format('DD/MM/YYYY')
        : '';
      item.validToDate = item.validToDate
        ? moment(item.validToDate).format('DD/MM/YYYY')
        : '';
      item.panelDate = item.panelDate
        ? moment(item.panelDate).format('DD/MM/YYYY')
        : '';
      item.issueDate = moment().format('DD/MM/YYYY');
      if (item.personMembership) {
        item.personMembership.expiryDate = item?.personMembership?.expiryDate
          ? moment(item?.personMembership?.expiryDate)
              ?.locale('en')
              .format('DD/MM/YYYY')
          : '-';
      }
      item.membershipNum = item?.personMembership?.membershipNum;
      item.classificationType =
        GetLanguage() === 'ar'
          ? item?.kseIssuedCertType?.nameAr
          : item?.kseIssuedCertType?.nameEn;
    }
    item.nationalityL =
      GetLanguage() === 'ar'
        ? item.nationality?.nameArabic
        : item?.nationality?.nameEng;
    item.certTypeName =
      GetLanguage() === 'ar'
        ? item?.kseIssuedCertType?.nameAr
        : item?.kseIssuedCertType?.nameEn;
    this.loading = false;
    this.printCertificatData = item;
  }

  print() {
    this.coreService
      .putRequest(
        `KSEIssuedCertificate/IncreamentPrintCount?id=${this.printCertificatData.id}`,
        {},
      )
      .subscribe(
        (res) => {},
        (err) => {
          // this.toastr.error(HandleResponseError(err));
        },
      );
  }
}
