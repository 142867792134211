import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { secureStorage } from '../functions/secure-storage';
import { GetLanguage } from '../functions/shared-functions';
import { AngularEditorConfig } from '@kolkov/angular-editor';

export const PageNumber = 1;
export const PageSize = 20;
export const today = new Date();
export const phone_pattern = '[- +()0-9]+';
export const httpsPattern = /^https:\/\//;
export const numbers_pattern = /^-?(0|[1-9]\d*)?$/;
export const currentLanguage = secureStorage.getItem('lang') || 'ar';
export const dateFormat =
  currentLanguage === 'en' ? 'DD-MM-yyyy' : 'D MMM, yyyy';
export const dateTimeFormat =
  currentLanguage === 'en' ? 'DD/MM/YYYY, HH:mm A' : 'YYYY/MM/DD, HH:mm A';
export const dateTimeCompleteFormat = 'DD/MM/YYYY, HH:mm:ss A';
export const monthDateFormat = currentLanguage === 'en' ? 'M/yyyy' : 'yyyy/M';
export const hijriFormat = 'iD iMMM، iYYYY';
export const datePipe = new DatePipe('en');
export const serverDate = 'DD/MM/yyyy';
export const serverDateTime = 'YYYY-MM-DDTHH:mm:ss';
export const serverTime = 'HH:mm a';
export const filterDate = 'yyyy-MM-DD';
export const primaryColor = (window as any).primaryColor;
export const isMobile = window.innerWidth < 1000;
export const myIpAccessKey = '991dbc0aa9defca65d96090a8317460b';
export const defaultAvatar = 'assets/img/avatars/user-avatar.webp';
export const ArabicNumbersD = [
  '٠',
  '١',
  '٢',
  '٣',
  '٤',
  '٥',
  '٦',
  '٧',
  '٨',
  '٩',
];
export const ArabicNumbers = [
  /٠/g,
  /١/g,
  /٢/g,
  /٣/g,
  /٤/g,
  /٥/g,
  /٦/g,
  /٧/g,
  /٨/g,
  /٩/g,
];
export const EnglishNumbers = [
  /0/g,
  /1/g,
  /2/g,
  /3/g,
  /4/g,
  /5/g,
  /6/g,
  /7/g,
  /8/g,
  /9/g,
];
export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const kuwait_civil_number_pattern = new RegExp(/^[0-9]{12}$/);
export const kuwait_phone_pattern = new RegExp(/^[569]\d{7}$/);
export const momentDateFormat = (date, normal?, format?) => {
  if (normal) {
    return moment(date).format(format || dateFormat);
  } else {
    return reverseString(
      moment(date)
        .locale(GetLanguage())
        .format(format || dateFormat),
    );
  }
};
export const momentMonthDateFormat = (date) => {
  return reverseString(moment(date).format('M/YYYY'));
};
const reverseString = (str) => {
  if (GetLanguage() === 'ar') {
    // moment.locale('ar_sa');
    return str
      .split('/')
      .reverse()
      .map((one) => [...one].join(''))
      .toString()
      .replaceAll(',', '/');
  } else {
    // moment.locale('en-us');
    return str;
  }
};
export const config: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '8rem',
  minHeight: '5rem',
  placeholder: 'Enter text here...',
  translate: 'yes',
  defaultParagraphSeparator: 'p',
  defaultFontName: 'Arial',
  toolbarHiddenButtons: [[]],
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText',
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
};

export const MY_FORMATS = {
  parse: {
    dateInput: dateFormat,
  },
  display: {
    dateInput: dateFormat,
    monthYearLabel: dateFormat,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CIVIL_ID_LIST = [
  'users',
  'logoutUsers',
  'receptionDataReview',
  'receptionVerifyCertificate',
  'receptionReceipts',
  'personDataAboard',
  'accServiceRequest',
  'accCashier',
  'accReceipts',
  'accRequestPayment',
  'receptionCashier',
  'transformPerson',
  'workforce',
  'workforceNew',
  'workforceTransfered',
  'workforceTransferredNew',
  'results',
  'reEvaluate',
  'final-reviews',
  'final-audition',
  'Completing-documents',
  'Accepted-files',
  'Rejected-files',
  'Not-completed-files',
  'Blocked-files',
  'fileManagement',
  'classification-data-review',
  'issuedCertificate',
  'addPanelExaminer',
  'inquiries',
  'memberships-management',
  'auditEntries',
  'oldPersonsClassification',
  'membershipCards',
  'classificationCertificates',
  'classificationCards',
  'classificationRequests',
  'classificationStamp',
  'finacialTransaction',
  'companies',
  'removeExaminers',
];
export const ACCOUNT_TYPE_LIST = ['users', 'logoutUsers'];
export const USERNAME_LIST = ['users', 'logoutUsers'];
export const EMAIL_LIST = ['users', 'contactUs', 'logoutUsers', 'companies'];
export const PHONE_LIST = [
  'users',
  'logoutUsers',
  'receptionCashier',
  'companies',
  'contactUs',
];
export const PASSPORT_LIST = [
  'receptionDataReview',
  'receptionVerifyCertificate',
  'receptionReceipts',
  'personDataAboard',
  'accServiceRequest',
  'accCashier',
  'accReceipts',
  'accRequestPayment',
  'receptionCashier',
  'transformPerson',
  'workforce',
  'workforceNew',
  'workforceTransfered',
  'workforceTransferredNew',
  'results',
  'reEvaluate',
  'final-reviews',
  'final-audition',
  'Completing-documents',
  'Accepted-files',
  'Rejected-files',
  'Not-completed-files',
  'Blocked-files',
  'fileManagement',
  'membershipCards',
  'classification-data-review',
  'issuedCertificate',
  'addPanelExaminer',
  'inquiries',
  'memberships-management',
  'auditEntries',
  'oldPersonsClassification',
  'classificationCertificates',
  'classificationCards',
  'classificationRequests',
  'classificationStamp',
  'finacialTransaction',
  'removeExaminers',
];
export const REVIEW_DATE_LIST = ['receptionDataReview', 'receptionCashier'];
export const FORM_DATE_LIST = [
  'receptionReceipts',
  'accReceipts',
  'workforceTransfered',
  'workforceTransferredNew',
  'workforce',
  'workforceNew',
  'issuedCertificate',
  'auditEntries',
  'paymentHistory',
  'membershipCards',
  'classificationCertificates',
  'classificationCards',
  'classificationRequests',
  'classificationRequests',
];
export const TO_DATE_LIST = [
  'receptionReceipts',
  'accReceipts',
  'workforceTransfered',
  'workforceTransferredNew',
  'workforce',
  'workforceNew',
  'issuedCertificate',
  'auditEntries',
  'paymentHistory',
  'membershipCards',
  'classificationCertificates',
  'classificationCards',
  'classificationRequests',
  'classificationStamp',
];
export const FILE_NUM_LIST = [
  'receptionReceipts',
  'accServiceRequest',
  'accCashier',
  'accReceipts',
  'accRequestPayment',
  'transformPerson',
  'results',
  'reEvaluate',
  'final-reviews',
  'final-audition',
  'Completing-documents',
  'Accepted-files',
  'Rejected-files',
  'Not-completed-files',
  'Blocked-files',
  'fileManagement',
  'workforce',
  'workforceNew',
  'classification-data-review',
  'companies',
  'addPanelExaminer',
  'inquiries',
  'memberships-management',
  'oldPersonsClassification',
  'membershipCards',
  'classificationCertificates',
  'classificationCards',
  'classificationRequests',
  'classificationStamp',
  'personStatusByUserReport',
  'serviceRequestByUserReport',
  'removeExaminers',
];

export const RECEIPT_NUM_LIST = [
  'receptionReceipts',
  'accReceipts',
  'issuedCertificate',
  'membershipCards',
];
export const NAME_LIST = [
  'eduRating',
  'certificateType',
  'college',
  'collegeUni',
  'profession',
  'subProfession',
  'approvedYear',
  'accServiceRequest',
  'evaluationType',
  'evaluationStatus',
  'evaluationSetting',
  'panelFacultyMembers',
  'panelExaminers',
  'results',
  'facultyMember',
  'final-reviews',
  'final-audition',
  'nationalities',
  'countries',
  'genders',
  'residencyTypes',
  'jobs',
  'documentTypes',
  'districts',
  'certVerification',
  'certverStatus',
  'certvertypeStatus',
  'services',
  'serviceSetting',
  'paymentMethod',
  'discountType',
  'requestStatus',
  'organizations',
  'Completing-documents',
  'Accepted-files',
  'Rejected-files',
  'Not-completed-files',
  'Blocked-files',
  'companies',
  'ministries',
  'holidays',
  'accreditResults',
  'app-pages-settings',
  'roles-group',
  'page-settings',
  'status-type',
  'companyRating',
  'shift',
  'appointmentTypes',
  'appointmentInfo',
  'appointmentSetting',
  'panels',
  'addPanelExaminer',
  'inquiries',
  'memberships-management',
  'classificationTypes',
  'exams',
  'examTopics',
  'classificationPanels',
  'classificationRequests',
  'contactUs',
  'evaluation',
  'classfield',
  'jobs-recomanded',
  'removeExaminers',
  'courses',
  'trips',
  'other_services',
];
export const SERVICE_LIST = [
  'accServiceRequest',
  'finacialTransaction',
  'financialReportByReceptionCashierReport',
  'financialReportByCashierReport',
  'societyCashierMonthlyReport',
  'societyCashierReport',
  'receptionCashierMonthlyReport',
  'receptionCashierReport',
  'financialReportByServiceCashierReport',
  'financialReportByServiceReceptionCashierReport',
];
export const FROM_TO_DATE_REQUIRED_LIST = [
  'finacialTransaction',
  'financialReportByReceptionCashierReport',
  'financialReportByCashierReport',
  'evaluationDetailsReport',
  'societyCashierMonthlyReport',
  'societyCashierReport',
  'receptionCashierReport',
  'financialReportByServiceCashierReport',
  'financialReportByServiceReceptionCashierReport',
  'evaluationByNationalityReport',
  'evaluationByJobReport',
  'classification_results',
  'evalution_results',
  'classification_panels',
  'evalution_panels',
  'personStatusByUserReport',
  'serviceRequestByUserReport',
  'personOperationsReport',
  'classification_report',
];
export const MEMBERSHIP_NUM_LIST = [
  'accCashier',
  'inquiries',
  'memberships-management',
  'membershipCards',
];
export const ARABIC_ENGLISH_NAME_LIST = [
  'classification-data-review',
  'membership-types',
  'fo-pages',
  'notificationCategory',
];
export const PAYMENT_METHOD_LIST = [
  'finacialTransaction',
  'dailyAccountingReport',
];
export const USER_LIST = [
  'finacialTransaction',
  'personStatusByUserReport',
  'serviceRequestByUserReport',
];
export const ACTION_CODE_LIST = ['documentTypes'];
export const COMPANY_FILE_NAME_LIST = ['receptionDataReview'];
export const DATE_LIST = ['panels', 'classificationPanels'];
export const KUWAIT_PHONE_FULL_NAME_LIST = [
  'inquiries',
  'memberships-management',
];
export const EVALUATION_TYPE_LIST = [
  'evaluation',
  'evaluationDetailsReport',
  'evaluationByNationalityReport',
  'evaluationByJobReport',
  'successAndFailedStatisticsReport',
  'evaluationSuccessAndFailureReport',
  'classification_results',
  'evalution_results',
];
export const CLASSIFICATION_TYPE_LIST = [
  'classificationCards',
  'classificationCertificates',
];
export const EVALUATION_DATE_LIST = ['evaluation'];
export const PROFESSION_LIST = ['evaluation', 'evaluationDetailsReport', 'examiners_for_facultyMembers_classification', 'examiners_for_facultyMembers_evaluation', 'examiners_panels_for_facultyMembers_evaluation', 'examiners_panels_for_facultyMembers_classification'];
export const Year_LIST = ['examiners_for_facultyMembers_classification', 'examiners_for_facultyMembers_evaluation', 'facultyMembers_classification', 'facultyMembers_evaluation', 'examiners_panels_for_facultyMembers_evaluation', 'examiners_panels_for_facultyMembers_classification'];
export const IS_MEMBERSHIP_LIST = ['application-settings'];
export const CATEGORY_NAME_LIST = ['auditCategory'];
export const NOC_LIST = ['profession', 'subProfession'];
export const CASHIER_USER_LIST = [
  'financialReportByReceptionCashierReport',
  'financialReportByCashierReport',
  'societyCashierMonthlyReport',
  'societyCashierReport',
  'receptionCashierMonthlyReport',
  'receptionCashierReport',
  'financialReportByServiceCashierReport',
  'financialReportByServiceReceptionCashierReport',
  'personOperationsReport',
];
export const NATIONALITY_LIST = [
  'evaluationDetailsReport',
  'evaluationByNationalityReport',
  'evaluationByJobReport',
];
export const JOB_LIST = [
  /*"evaluationDetailsReport",*/
  'evaluationByNationalityReport',
  'evaluationByJobReport',
];
export const EVALUATION_STATUS_LIST = [
  'evaluationDetailsReport',
  'evaluationByNationalityReport',
  'evaluationByJobReport',
  'successAndFailedStatisticsReport',
  'classification_results',
  'evalution_results',
];
export const RESIDENCY_TYPE_LIST = [
  'evaluationByNationalityReport',
  'evaluationByJobReport',
];
export const STATUS_TYPE_LIST = [
  'personStatusByUserReport',
  'serviceRequestByUserReport',
];
export const COMPANY_NAME_LIST = ['inquiries'];
export const COMPANY_FILE_NUM_LIST = ['inquiries'];
