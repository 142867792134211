<page-container
  [hasFilter]="true"
  [listName]="'issuedCertificate'"
  [hasGrid]="false"
  [valuesGoingToFilter]="valuesGoingToFilter"
>
  <app-data-table
    [columns]="tableColumns"
    (getData)="getServerData($event)"
    [total]="total"
    [data]="issuedCertificateData"
    [pluralTableLabel]="'Issued certificates'"
    [singleTableLabel]="'Issued certificates'"
    (printCertificate)="printCertificate($event)"
    [pageIndexNum]="paginationEvent.pageIndex - 1"
  ></app-data-table>
</page-container>
