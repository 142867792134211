import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GetLanguage } from '../../../shared/functions/shared-functions';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class PageTitleService {
  public titleEvent$: Subject<any> = new Subject<any>();

  constructor(
    private title: Title,
    private translateService: TranslateService,
  ) {}

  setTitle(value: string) {
    this.titleEvent$.next(value);
    this.title.setTitle(
      `${GetLanguage() === 'ar' ? 'جمعيه المهندسين الكويتيه' : 'Kuwait Society of Engineers'} - ${this.translateService.instant(value)}`,
    );
  }

  getTitle() {
    return this.title.getTitle();
  }
}
