import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  GetCurrentUser,
  GetLanguage,
  HandleResponseError,
} from '../../../app/shared/functions/shared-functions';
import { secureStorage } from '../../../app/shared/functions/secure-storage';
import { environment } from '../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoreService } from '../../../app/services/core.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../app/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery: boolean;
  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  nationality: string;
  nationality_error;
  countryDetails;
  languageList = [];
  features;
  navigationItems = this.navigationService.items;
  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal'),
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical'),
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar'),
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar'),
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible),
  );

  megaMenuOpen$: Observable<boolean> = of(false);
  selectedLanguage;
  mainLogo =
    GetLanguage() === 'ar'
      ? environment.mainLogo_arabic
      : environment.mainLogo_english;
  searchCtrl = new UntypedFormControl();

  language = GetLanguage();
  menu_items = [];
  searched_menu_items = [];

  cashCount;
  personstatusCount;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private coreService: CoreService,
    private authService: AuthService,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.getPersonOpr();
    this.subscribeToLogin();
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onSearchChange(value));
    this.languageList = [
      { code: 'en', label: 'English', flag: 'flag:united-states' },
      { code: 'ar', label: 'Arabic', flag: 'flag:kuwait' },
    ];
    this.selectedLanguage =
      this.languageList.find((x) => x.code === GetLanguage()) ||
      this.languageList[0];
    this.subscribeToQuickList();
    const items = this.navigationService.items;
    items.map((item: any) => {
      if (item.type === 'dropdown') {
        item.children.map((child) => {
          child.translated_label = this.translate.instant(child.label);
          this.menu_items.push(child);
        });
      } else {
        item.translated_label = this.translate.instant(item.label);
        this.menu_items.push(item);
      }
    });
    this.searched_menu_items = this.menu_items;
  }

  subscribeToLogin() {
    this.authService.loggedIn.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.getCountryFlag(GetCurrentUser());
      }
    });
  }

  onSearchChange(value: string) {
    this.searched_menu_items = this.menu_items.filter(
      (x) =>
        x.translated_label?.toLowerCase()?.indexOf(value) !== -1 ||
        x.label?.toLowerCase()?.indexOf(value?.toLowerCase()) !== -1,
    );
  }

  getCountryFlag(userInfo) {
    const apiUrl = 'https://restcountries.com/v3.1/name/';
    if (userInfo?.nationalityNameEn) {
      this.http.get(`${apiUrl}${userInfo?.nationalityNameEn}`).subscribe(
        (data) => {
          this.nationality = data[0]?.translations?.ara?.official;
          this.countryDetails = data[0];
        },
        (error) => {
          console.log('Error fetching country details:', error);
        },
      );
    }
  }

  subscribeToQuickList() {
    this.configService.quickList$
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.features = res;
      });
  }

  openQuickpanel(): void {
    // this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  changeSiteLanguage(localeCode: string): void {
    const current = GetLanguage();
    this.selectedLanguage = this.languageList.find(
      (x) => x.code === localeCode,
    );
    if (current !== localeCode) {
      secureStorage.setItem('lang', localeCode);
      window.location.reload();
    }
  }

  removeAll() {}

  removeQuickAccess(event, item) {
    // this.popoverRef.close();
    event.stopPropagation();
    this.coreService
      .deleteRequest(`admin/quick-list/${item?.id}`)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.features = this.features.filter((x) => x.id !== item.id);
          this.toastr.success(res?.message);
        },
        (error) => {
          this.toastr.error(HandleResponseError(error));
        },
        () => {},
      );
  }

  getPersonOpr() {
    this.coreService
      .getRequest(`Reports/GetPersonOpsScreen?userId=${GetCurrentUser()?.id}`)
      .subscribe((res) => {
        this.cashCount = res?.cashCount;
        this.personstatusCount = res.personstatusCount;
      });
  }
}
