<a
  *ngIf="isLink(item) && !isFunction(item.route) && roles.includes(item.role)"
  [routerLink]="item.route"
  [fragment]="item.fragment"
  matRipple
  matRippleColor="var(--sidenav-item-ripple-color)"
  class="vex-sidenav-item d-flex justify-content-between"
>
  <a
    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
    routerLinkActive="vex-sidenav-item--active"
    class="d-flex align-items-center"
    style="width: 80%"
  >
    <mat-icon
      *ngIf="level === 0"
      [svgIcon]="item.icon"
      class="vex-sidenav-item__icon"
    ></mat-icon>
    <span
      class="vex-sidenav-item__label inner-link"
      [ngClass]="item.textClass"
      >{{ item.label | translate }}</span
    >
  </a>
  <span
    *ngIf="item.badge"
    [ngClass]="[item.badge.bgClass, item.badge.textClass]"
    class="vex-sidenav-item__badge"
  >
    <span *ngIf="item.badge.value">{{ item.badge.value }}</span>
    <!--    <mat-icon (click)="addToFast()" class="vex-sidenav-item__dropdown-icon add-icon"-->
    <!--              tooltip="{{'Add to quick access menu' | translate}}"-->
    <!--              *ngIf="item.badge.icon" svgIcon="{{item?.badge?.icon}}"></mat-icon>-->
  </span>
</a>

<div
  (click)="item.route()"
  *ngIf="isLink(item) && isFunction(item.route) && roles.includes(item.role)"
  class="vex-sidenav-item"
  matRipple
  matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active"
>
  <mat-icon
    *ngIf="level === 0"
    [svgIcon]="item.icon"
    class="vex-sidenav-item__icon"
  ></mat-icon>
  <span class="vex-sidenav-item__label inner-link" [ngClass]="item.textClass">{{
    item.label | translate
  }}</span>
  <span
    *ngIf="item.badge"
    [ngClass]="[item.badge.bgClass, item.badge.textClass]"
    class="vex-sidenav-item__badge"
  >
    <span *ngIf="item?.badge?.value">{{ item?.badge?.value }}</span>
    <!--    <mat-icon (click)="addToFast()" class="vex-sidenav-item__dropdown-icon add-icon" tooltip="Add to quick access menu"-->
    <!--              *ngIf="item?.badge?.icon" svgIcon="{{item?.badge?.icon}}"></mat-icon>-->
  </span>
</div>

<ng-container *ngIf="isDropdown(item) && roles.includes(item.role)">
  <div
    (click)="toggleOpen()"
    [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen"
    class="vex-sidenav-item"
    matRipple
    matRippleColor="var(--sidenav-item-ripple-color)"
  >
    <mat-icon
      *ngIf="level === 0"
      [svgIcon]="item.icon"
      class="vex-sidenav-item__icon"
    ></mat-icon>
    <span class="vex-sidenav-item__label font-bold">{{
      item.label | translate
    }}</span>
    <span
      *ngIf="item.badge"
      [ngClass]="[item.badge.bgClass, item.badge.textClass]"
      class="vex-sidenav-item__badge"
      >{{ item.badge.value | translate }}</span
    >
    <mat-icon
      class="vex-sidenav-item__dropdown-icon"
      [style]="isRTL ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"
      svgIcon="mat:keyboard_arrow_right"
    ></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <sidenav-item
      *ngFor="let subItem of item.children"
      [item]="subItem"
      [level]="level + 1"
    ></sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isSubheading(item)">
  <div class="vex-sidenav-subheading">{{ item.label | translate }}</div>
  <sidenav-item
    *ngFor="let subItem of item.children"
    [item]="subItem"
    [level]="0"
  ></sidenav-item>
</ng-container>
