import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { secureStorage } from '../shared/functions/secure-storage';
import moment from 'moment';
import { datePipe } from '../shared/variables/variables';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  filterDataChangedSubject = new BehaviorSubject<string>(undefined);
  outOfTableSubject = new BehaviorSubject<boolean>(undefined);
  ///////////////
  drawerOpen = new BehaviorSubject<boolean>(false);
  drawerOpen$ = this.drawerOpen.asObservable();
  ///////////////
  examsId = new BehaviorSubject<any>(undefined);
  examsIdObservable$ = this.examsId.asObservable();
  ///////////////
  surveyId = new BehaviorSubject<any>(undefined);
  surveyIdObservable$ = this.surveyId.asObservable();
  ///////////////
  crumbs = new BehaviorSubject<any>(undefined);
  crumbsObservable$ = this.crumbs.asObservable();
  ///////////////
  statistics_data = new BehaviorSubject<any>(undefined);
  statistics_dataObservable$ = this.statistics_data.asObservable();
  ///////////////
  sendSubmit = new BehaviorSubject<any>(undefined);
  sendSubmitObservable$ = this.sendSubmit.asObservable();
  ///////////////
  toggleListGridSubject = new BehaviorSubject<any>('table');
  toggleListGridObservable$ = this.toggleListGridSubject.asObservable();
  ///////////////
  downloadAsPdf = new BehaviorSubject<any>(undefined);
  ///////////////
  downloadAsExcel = new BehaviorSubject<any>(undefined);
  ///////////////
  listEvent = new BehaviorSubject<any>(undefined);
  listEventObservable$ = this.listEvent.asObservable();
  ///////////////
  imageViewer = new BehaviorSubject<any>(undefined);
  imageViewerObservable$ = this.imageViewer.asObservable();
  ///////////////
  progressMessage = new BehaviorSubject<any>(null);
  progressMessageObservable$ = this.progressMessage.asObservable();

  constructor(private http: HttpClient) {}

  getMyCountryByIp(): any {
    this.http
      .get('https://api.ipify.org?format=json')
      .subscribe((data: any) => {
        this.http
          .get(`https://ipinfo.io/${data.ip}/json?token=e48dad5f0ed21d`)
          .subscribe((data: any) => {
            // console.log('dataaaa', data);
            secureStorage.setItem('location', {
              country: data?.country,
              city: data?.city,
            });
          });
      });
  }

  getPreviousDay() {
    return datePipe.transform(
      new Date(
        moment().subtract(1, 'days').format('YYYY-MM-DD hh:mm'),
      ).setHours(0, 0, 0, 0),
      'yyyy-MM-dd HH:mm:ss',
    );
  }

  getCurrentDay() {
    return datePipe.transform(
      new Date(
        moment().format('YYYY-MM-DD hh:mm'),
      ).setHours(0, 0, 0, 0),
      'yyyy-MM-dd HH:mm:ss',
    );
  }

  getMonthFirstDay() {
    return datePipe.transform(
      new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm')).setHours(
        0,
        0,
        0,
        0,
      ),
      'yyyy-MM-dd HH:mm:ss',
    );
  }

  getPreviousMonth() {
    return datePipe.transform(
      new Date(
        moment()
          .subtract(1, 'month')
          .format('YYYY-MM-DD hh:mm'),
      ).setHours(0, 0, 0, 0),
      'yyyy-MM-dd HH:mm:ss',
    );
  }
}
