import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
//import {NgxEasypiechartModule} from 'ngx-easypiechart';
//import {PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import { DeleteDialogComponent } from './pop-up/delete-dialog/delete-dialog.component';
/**
 * This will import all modules from echarts.
 * If you only need custom modules,
 * please refer to [Custom Build] section.
 */
import * as echarts from 'echarts';
import { OthersPopupComponent } from './pop-up/others-popup/others-popup.component';
import { BarcodeDialogComponent } from './pop-up/barcode-dialog/barcode-dialog.component';
import { NgxPrintModule } from 'ngx-print';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { PersonDataTransferPopupComponent } from './pop-up/person-data-transfer-popup/person-data-transfer-popup.component';
import { DetailsPopupComponent } from './pop-up/details-popup/details-popup.component';
import { SharedModule } from '../../shared/shared.module';
import { AngularMaterialModule } from '../../shared/material.module';
import { ConfirmDialogComponent } from 'src/app/modals/confirm-dialog/confirm-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgMultiselectDropdown3Module } from 'ng-multiselect-dropdown3';
@NgModule({
  declarations: [
    DeleteDialogComponent,
    ConfirmDialogComponent,
    OthersPopupComponent,
    BarcodeDialogComponent,
    PersonDataTransferPopupComponent,
    DetailsPopupComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatSnackBarModule,
    TranslateModule,
    MatChipsModule,
    MatListModule,
    MatTableModule,
    NgxPrintModule,
    NgxBarcode6Module,
    SharedModule,
    AngularMaterialModule,
    QRCodeModule,
    NgMultiselectDropdown3Module,
  ],
  exports: [],
})
export class WidgetComponentModule {}
