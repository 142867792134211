<page-container [hasFilter]="true" [listName]="'accCashier'" [hasGrid]="false">
  <app-data-table
    *ngIf="showTable"
    [hasAdd]="false"
    [hasDelete]="false"
    [hasModify]="false"
    [hasRequestStatus]="true"
    [columns]="tableColumns"
    [data]="users"
    [pluralTableLabel]="'Cashier'"
    [singleTableLabel]="'Cashier'"
    (cachierCollect)="cachierCollect($event)"
    (collectSelectedRow)="collectSelectedRow($event)"
    [total]="total"
    (fireGetServerData)="getData($event)"
    (getData)="getData($event)"
    [pageIndexNum]="paginationEvent.pageIndex - 1"
  ></app-data-table>
</page-container>
<div
  *ngIf="!showTable"
  class="d-flex col-sm-12 justify-content-center align-items-center py-5"
>
  <!--  <mat-spinner [diameter]="80"></mat-spinner>-->
  <div class="dotted-loader"></div>
</div>
