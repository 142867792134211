<div class="certificate-pr" *ngIf="!loading; else loader">
  <div fxLayout="row" fxLayoutAlign="center" style="padding: 0.5rem 2rem">
    <button
      (click)="print()"
      printSectionId="{{ print_id }}"
      ngxPrint
      mat-button
      mat-raised-button
      [useExistingCss]="true"
      color="primary"
      class="mat-focus-indicator mat-primary print-button"
      style=""
    >
      {{ 'PrintCertificate' | translate }}
    </button>
  </div>
  <div id="ToWhomeNeedCertNOC" class="px-4">
    <div
      class="print-content"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      style="width: 100%; height: 100%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="outer-border w-100">
          <div class="inner-dotted-border">
            <div
              class="mb-0 position-relative"
              fxLayout="row wrap"
              fxLayoutAlign="space-between start"
            >
              <div style="width: 45%; text-align: start">
                <span class="certify">
                  <span class="header-info">التاريخ : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.issueDate
                  }}</span>
                </span>
              </div>
            </div>
            <span class="certification">شهاده عضوية</span>
            <br /><br />
            <div class="mt-2" fxLayout="row wrap">
              <div fxFlex="100" class="d-flex justify-content-start">
                <span class="certify">
                  <span class="font-bold"
                    >تشهد جمعية المهندسين الكويتية&nbsp;</span
                  ><span>&nbsp; بأن السيد/&nbsp;</span
                  ><span class="name">{{
                    printCertificatData?.arabicName
                  }}</span>
                </span>
              </div>
            </div>
            <div class="" fxLayout="column wrap" fxLayoutAlign="start center">
              <div class="w-100 d-flex my-0">
                <span class="certify gaped">
                  <span style="font-size: 22px"
                    >ويحمل بطاقة مدنية رقم&nbsp;:&nbsp;</span
                  >
                  <span style="font-size: 22px">{{
                    printCertificatData?.civilID
                  }}</span>
                </span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%"
              >
                <div style="width: fit-content">
                  <span class="certify">
                    <span style="font-size: 22px"
                      >مسجل لديها كعضو منتسب بتاريخ&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{ printCertificatData?.startDate }} &nbsp;</span
                    >
                  </span>
                </div>
                <div style="width: fit-content" class="certify gaped">
                  <span style="font-size: 22px">تخصص&nbsp;:&nbsp; </span>
                  <span style="font-size: 22px">{{
                    printCertificatData?.profession
                  }}</span>
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start"
                style="width: 100%; gap: 113px"
              >
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >ورقم عضويته
                      <span *ngIf="membership_type === 'permanentMembership'"
                        >الدائمة</span
                      >
                      &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.personMembership.membershipNum
                      }}
                      &nbsp;</span
                    >
                  </span>
                </div>

                <div
                  style="width: fit-content"
                  class="row my-0"
                  *ngIf="membership_type !== 'permanentMembership'"
                >
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تاريخ الإنتهاء &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{ printCertificatData?.endDate }} &nbsp;</span
                    >
                  </span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start " style="width: 100%">
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >وبناءا علي طلبه اعطيت له هذه الشهادة
                    </span>
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start my-0 w-100"
                fxLayout="row wrap"
                fxLayoutAlign="center"
              >
                <div>
                  <span style="font-size: 22px; text-align: right"
                    >دون ادنى مسئولية على الجمعية تجاه الغير، علما بأن هذه
                    الشهادة صالحة لمدة ثلاثة أشهر من تاريخ الإصدار.</span
                  >
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: start"
                  class="my-0"
                >
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >رقم الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptNum
                    }}</span>
                  </span>
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تاريخ الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptDate
                    }}</span>
                  </span>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: center; padding-top: 50px"
                  class=""
                >
                  <span class="certify">
                    <span
                      class="fs-30 w-100 font-bold signature"
                      style="font-size: 23px"
                      >جمعية المهندسين الكويتية</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 20%; text-align: center"
                >
                  <qrcode
                    id="qr"
                    [elementType]="qr_elementType"
                    [qrdata]="qrdata"
                    [scale]="scale"
                    [width]="qr_width"
                  ></qrcode>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 30%; text-align: center"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" style="padding: 0.5rem 2rem">
    <button
      (click)="print()"
      printSectionId="{{ print_id }}"
      ngxPrint
      mat-button
      mat-raised-button
      [useExistingCss]="true"
      color="primary"
      class="mat-focus-indicator mat-primary print-button"
      style=""
    >
      {{ 'PrintCertificate' | translate }}
    </button>
  </div>
</div>
<ng-template #loader>
  <div class="col-sm-12 justify-content-center align-items-center d-flex">
    <!--    <div class="spinner-border loader">-->
    <!--      <span class="loading__anim" id=""></span>-->
    <!--    </div>-->
    <div class="dotted-loader"></div>
  </div>
</ng-template>
