import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CoreService } from 'src/app/services/core.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private coreService: CoreService,
  ) {}

  getFirstApiData(): Observable<any> {
    // return this.http.get('http://161.97.65.140:12013/api/ApplicationSetting/ServerTime');

    return this.coreService.getRequest(`ApplicationSetting/ServerTime`);
  }
}
