import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { AuthService } from '../../../../../app/services/auth.service';
import {
  GetCurrentUser,
  GetLanguage,
} from '../../../../../app/shared/functions/shared-functions';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '../../../../../app/services/core.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@UntilDestroy()
@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:lock',
      label: 'Change Password',
      description: '',
      colorClass: 'text-teal',
      route: '/change-password',
    },
    {
      id: '2',
      icon: 'mat:settings',
      label: 'Settings',
      description: '',
      colorClass: 'text-teal',
      route: '/settings/dashboard-settings',
    },
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green',
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange',
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red',
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray',
    },
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  userData: any;
  isRTL: boolean;
  userImageError;

  constructor(
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private coreService: CoreService,
    public translate: TranslateService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
  ) {}

  ngOnInit() {
    this.userData = GetCurrentUser();
    this.isRTL = GetLanguage() === 'ar';
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.logoutUser();
    this.popoverRef.close();
    this.authService.logOut();
  }

  logoutUser() {
    const userid = [GetCurrentUser()?.id];
    this.coreService.putRequest(`User/Logout`, userid).subscribe((res) => {});
  }
}
