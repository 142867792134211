import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './core/components/layout/custom-layout/custom-layout.component';
import { ChangePasswordComponent } from './modules/authentication/change-password/change-password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingGuard } from './core/guards/landing.guard';
import { TermsConditionsComponent } from './shared/component/terms-conditions/terms-conditions.component';
import { GroupedCashierComponent } from './modules/grouped-cashier/grouped-cashier.component';
import { BreadcrumbTranslateService } from './services/breadcrumb-translate.service';
import { IssuedCertificateListComponent } from './modules/issued-certificate/issued-certificate-list/issued-certificate-list.component';
import { IssuedCertificateResolver } from './resolvers/issued-certificate.resolver';
import { PrintCertificatComponent } from './modules/issued-certificate/print-certificate/print-certificate.component';
import { ServiceCollectionComponent } from './shared/component/service-collection/service-collection.component';
import { PrintScreenComponent } from './shared/component/print-screen/print-screen.component';
import { NOCCertificateComponent } from './modules/issued-certificate/noc-certificate/noc-certificate.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [LandingGuard],
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
    data: {},
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'admin-management',
        loadChildren: () =>
          import('./modules/users-management/admin-management.module').then(
            (m) => m.AdminManagementModule,
          ),
        canActivate: [AuthGuard],
        data: {
          title: 'Admin management',
          // breadcrumbs: '{{breadcrumb}}'
        },
        resolve: {
          // breadcrumb: BreadcrumbTranslateService
        },
      },
      {
        path: 'management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/system-management/system-management.module').then(
            (m) => m.SystemManagementModule,
          ),
        data: {
          title: 'System management',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'file-management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/system-management/file-management/file-mangement.module'
          ).then((m) => m.FileManagementModule),
        data: {
          title: 'File management',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'files-statuses-notes',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/system-management/files-statuses-notes/FilesStatusesNotes.module'
          ).then((m) => m.FilesStatusesNotesModule),
        data: {
          title: 'File management',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'reception',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/reception/reception.module').then(
            (m) => m.ReceptionModule,
          ),
        data: {
          title: 'Reception',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'payment',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/payment/payment.module').then(
            (m) => m.PaymentModule,
          ),
        data: {
          title: 'Payment',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'review',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/review/review.module').then((m) => m.ReviewModule),
        data: {
          title: 'Review',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'accounting',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/accounting/accounting.module').then(
            (m) => m.AccountingModule,
          ),
        data: {
          title: 'Accounting',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'evaluation',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/evaluation-management/evaluation-management.module'
          ).then((m) => m.EvaluationManagementModule),
        data: {
          title: 'Evaluation',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'other-destinations',
        loadChildren: () =>
          import('./modules/other-destinations/other-destinations.module').then(
            (m) => m.OtherDestinationsModule,
          ),
        canActivate: [AuthGuard],
        data: {
          title: 'Other destinations',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./modules/chat/chat.module').then((m) => m.ChatModule),
        canActivate: [AuthGuard],
        data: {
          title: 'Chat',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/reports/reports.module').then(
            (m) => m.ReportsModule,
          ),
        canActivate: [AuthGuard],
        data: {
          title: 'Reports',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'classification',
        loadChildren: () =>
          import('./modules/classification/classification.module').then(
            (m) => m.ClassificationModule,
          ),
        canActivate: [AuthGuard],
        data: {
          title: 'Classification',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./modules/membership/membership.module').then(
            (m) => m.MembershipModule,
          ),
        canActivate: [AuthGuard],
        data: {
          title: 'Membership cards',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'training-center',
        loadChildren: () =>
          import('./modules/training-center/training-center.module').then(
            (m) => m.TrainingCenterModule,
          ),
        canActivate: [AuthGuard],
        data: {
          title: 'Training center',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      // {
      //     path: 'mail-inbox',
      //     component: MailInboxComponent,
      //     canActivate: [AuthGuard],
      //     data: {
      //       title: 'Main inbox',
      //     },
      //     resolve: {
      //     }
      // },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Change password',
        },
        resolve: {},
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
        data: {
          title: 'Dashboard',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'classification-dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/classification-dashboard/classification-dashboard.module'
          ).then((m) => m.ClassificationDashboardModule),
        data: {
          title: 'Classification Dashboard',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'archive',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/archive/archive.module').then(
            (m) => m.ArchiveModule,
          ),
        data: {
          title: 'Other destinations',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'grouped-cashier-list',
        component: GroupedCashierComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Grouped cashier',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'terms-condition',
        component: TermsConditionsComponent,
      },
      {
        path: 'issued-certificate-list',
        component: IssuedCertificateListComponent,
        data: {
          title: 'Issued certificates',
          scrollDisabled: false,
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          IssuedCertificate: IssuedCertificateResolver,
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'print-certificate',
        component: PrintCertificatComponent,
        data: {
          title: 'Print certificate',
          scrollDisabled: false,
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'print-NOC-certificate',
        component: NOCCertificateComponent,
        data: {
          title: 'Print certificate',
          scrollDisabled: false,
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
      {
        path: 'service-collection',
        component: ServiceCollectionComponent,
        data: {
          scrollDisabled: false,
        },
      },
      {
        path: 'print-screen',
        component: PrintScreenComponent,
        data: {
          title: 'Print Screen',
          breadcrumbs: '{{breadcrumb}}',
        },
        resolve: {
          breadcrumb: BreadcrumbTranslateService,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
