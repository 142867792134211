<div class="img-viewer" *ngIf="selectedImage">
  <div class="img-viewer-overlay" (click)="closeImageViewer()"></div>
  <mat-icon
    class="cursor-pointer"
    tooltip="{{ 'Close' | translate }}"
    (click)="closeImageViewer()"
    >close</mat-icon
  >
  <img src="{{ selectedImage }}" (click)="$event.stopPropagation()" />
</div>
