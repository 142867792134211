<ng-template #sidenavRef>
  <sidenav [collapsed]="sidenavCollapsed$ | async"></sidenav>
</ng-template>

<ng-template #toolbarRef>
  <toolbar
    [hasShadow]="toolbarShadowEnabled$ | async"
    [mobileQuery]="!(isDesktop$ | async)"
    class="vex-toolbar"
  ></toolbar>
</ng-template>

<ng-template #footerRef>
  <app-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></app-footer>
</ng-template>

<ng-template #quickpanelRef>
  <quickpanel></quickpanel>
</ng-template>

<layout
  [footerRef]="footerRef"
  [quickpanelRef]="quickpanelRef"
  [sidenavRef]="sidenavRef"
  [toolbarRef]="toolbarRef"
></layout>

<!--<config-panel-toggle (openConfig)="configpanel.open()"></config-panel-toggle>-->

<!-- CONFIGPANEL -->
<sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <config-panel></config-panel>
</sidebar>
<!-- END CONFIGPANEL -->
