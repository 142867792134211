import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoreService } from '../../app/services/core.service';
import {
  FilterRes,
  GetCurrentUser,
  getCurrentUserRoles,
  GetLanguage,
} from '../../app/shared/functions/shared-functions';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../app/services/auth.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DropDownService {
  countries: any[] = [];
  colleges: any[] = [];
  eduRatings: any[] = [];
  professions: any[] = [];
  subProfessions: any[] = [];
  evalTypes: any[] = [];
  jobs: any[] = [];
  residencies: any[] = [];
  roles = getCurrentUserRoles();
  dropdowns: any[] = [];
  dropdowns_data = new BehaviorSubject<any[]>(null);
  dropdowns_dataObservable$ = this.dropdowns_data.asObservable();

  constructor(
    private _CoreService: CoreService,
    private authService: AuthService,
  ) {
    if (GetCurrentUser()) {
      this.getAll();
    } else {
      this.subscribeToLogin();
    }
  }

  getAll() {
    this.getCountriesList();
    this.getAllResidencyType();
    this.getSecAndCollegesList();
    this.getEduRatingsList();
    this.getProfessionsList();
    this.getSubProfessionsList();
  }

  subscribeToLogin() {
    this.authService.loggedIn.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.getAll();
      }
    });
  }

  getCountriesList() {
    if (getCurrentUserRoles()?.includes('GetAllCountry')) {
      this._CoreService.getRequest('Country/GetAllCountry').subscribe((res) => {
        this.countries = FilterRes(res);
        this.countries.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.dropdowns['countries'] = this.countries;
        this.prepareObservable();
      });
    }
  }

  getSecAndCollegesList() {
    if (getCurrentUserRoles()?.includes('GetAllSecAndCollage')) {
      this._CoreService
        .getRequest('SecAndCollage/GetAllSecAndCollage')
        .subscribe((res) => {
          this.colleges = FilterRes(res);
          this.colleges.map(
            (item) =>
              (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
          );
          this.dropdowns['colleges'] = this.colleges;
          this.prepareObservable();
        });
    }
  }

  getEduRatingsList() {
    if (getCurrentUserRoles()?.includes('GetAllEduRating')) {
      this._CoreService
        .getRequest('EduRating/GetAllEduRating')
        .subscribe((res) => {
          this.eduRatings = FilterRes(res);
          this.eduRatings.map(
            (item) =>
              (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
          );
          this.dropdowns['eduRatings'] = this.eduRatings;
          this.prepareObservable();
        });
    }
  }

  getProfessionsList() {
    if (getCurrentUserRoles()?.includes('GetAllProfession')) {
      this._CoreService
        .getRequest('Profession/GetAllProfession')
        .subscribe((res) => {
          this.professions = FilterRes(res);
          this.professions.map(
            (item) =>
              (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
          );
          this.dropdowns['professions'] = this.professions;
          this.prepareObservable();
        });
    }
  }

  getSubProfessionsList() {
    if (getCurrentUserRoles()?.includes('GetAllSubProfession')) {
      this._CoreService
        .getRequest('subProfession/GetAllsubProfession')
        .subscribe((res) => {
          this.subProfessions = FilterRes(res);
          this.subProfessions.map(
            (item) =>
              (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
          );
          this.dropdowns['subProfessions'] = this.subProfessions;
          this.prepareObservable();
        });
    }
  }

  getAllResidencyType() {
    if (getCurrentUserRoles()?.includes('GetAllResidencyType')) {
      this._CoreService.getRequest('ResidencyType').subscribe(
        (res) => {
          this.residencies = FilterRes(res);
          this.residencies.map(
            (item) =>
              (item.name =
                GetLanguage() === 'ar' ? item.nameArabic : item.nameEng),
          );
          this.dropdowns['residencies'] = this.residencies;
          this.prepareObservable();
        },
        (err) => {},
      );
    }
  }

  prepareObservable() {
    this.dropdowns_data.next(this.dropdowns);
  }
}
