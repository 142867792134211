import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { defaultAvatar } from '../../variables/variables';

@Component({
  selector: 'app-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss'],
})
export class GridCardComponent implements OnInit {
  @Input() item;
  @Output() openRowOuter = new EventEmitter<any>();
  defaultImage = defaultAvatar;
  constructor() {}

  ngOnInit() {}

  openRow() {
    this.openRowOuter.emit(this.item);
  }
}
