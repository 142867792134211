<div
  (click)="close()"
  [class.opaque]="invisibleBackdrop"
  [class.show]="opened"
  class="backdrop"
></div>

<div
  [class.open]="opened"
  [class.position-left]="positionLeft"
  [class.position-right]="positionRight"
  class="sidebar"
>
  <ng-content></ng-content>
</div>
