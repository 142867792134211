<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <!--    <a class="action flex-none"-->
    <!--       color="primary"-->
    <!--       mat-flat-button>-->
    <!--            <span>{{'Kuwait Society of Engineers - Admin Panel' | translate}}</span>-->
    <!--    </a>-->
    <img
      src="assets/img/logos/LastLog.png"
      alt="Kuwait Society of Engineers - Admin Panel"
    />
    <div class="font-medium hidden ltr:ml-4 rtl:mr-4 sm:block">
      {{ 'All rights reserved' | translate }}
    </div>
  </div>
</div>
