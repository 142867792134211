<div
  class="bg-pattern w-full h-full flex flex-col items-center justify-center pt-6"
>
  <div [@fadeInUp] class="card overflow-hidden w-full max-w-md">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="vertical-logo" src="{{ mainLogo }}" alt="" />
      </div>
    </div>
    <div class="text-center mt-4">
      <h2 class="title m-0">{{ 'Change Password' | translate }}</h2>
      <h4 class="body-2 text-secondary m-0">
        {{ 'Enter new password for password change.' | translate }}
      </h4>
    </div>
    <form
      [formGroup]="form"
      (ngSubmit)="send(form?.value)"
      autocomplete="off"
      class="p-6 flex flex-col w-100 align-items-center"
    >
      <mat-form-field class="flex-1 w-100">
        <mat-label>{{ 'Old Password' | translate }}</mat-label>
        <input
          [type]="oldInputType"
          formControlName="oldPass"
          matInput
          required
        />
        <button
          (click)="toggleVisibility('old')"
          mat-icon-button
          matSuffix
          tooltip="Toggle Visibility"
          type="button"
        >
          <mat-icon *ngIf="oldPassVisible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon
            *ngIf="!oldPassVisible"
            svgIcon="mat:visibility_off"
          ></mat-icon>
        </button>
        <mat-hint>{{
          'Click the eye to toggle visibility' | translate
        }}</mat-hint>
        <mat-error *ngIf="form.get('oldPass').hasError('required')">{{
          'You need to enter the old password' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field class="flex-1 w-100">
        <mat-label>{{ 'New Password' | translate }}</mat-label>
        <input
          [type]="newInputType"
          formControlName="newPass"
          matInput
          required
        />
        <button
          (click)="toggleVisibility('new')"
          mat-icon-button
          matSuffix
          tooltip="Toggle Visibility"
          type="button"
        >
          <mat-icon *ngIf="newPassVisible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon
            *ngIf="!newPassVisible"
            svgIcon="mat:visibility_off"
          ></mat-icon>
        </button>
        <mat-hint>{{
          'Click the eye to toggle visibility' | translate
        }}</mat-hint>
        <mat-error *ngIf="form.get('newPass').hasError('required')">{{
          'You need to enter the new password' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-1 w-100">
        <mat-label>{{ 'Confirm New Password' | translate }}</mat-label>
        <input
          [type]="confirmInputType"
          formControlName="confirmPassword"
          matInput
          required
        />
        <button
          (click)="toggleVisibility('confirm')"
          mat-icon-button
          matSuffix
          tooltip="Toggle Visibility"
          type="button"
        >
          <mat-icon *ngIf="confirmVisible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon
            *ngIf="!confirmVisible"
            svgIcon="mat:visibility_off"
          ></mat-icon>
        </button>
        <mat-hint>{{
          'Click the eye to toggle visibility' | translate
        }}</mat-hint>
        <mat-error *ngIf="form.get('confirmPassword').hasError('required')">{{
          'You need to enter confirm the new password' | translate
        }}</mat-error>
        <mat-error
          *ngIf="form.get('confirmPassword').hasError('confirmedValidator')"
          >{{ 'Passwords do not match' | translate }}</mat-error
        >
      </mat-form-field>
      <button
        [disabled]="!form?.valid"
        class="mt-2 w-50"
        color="primary"
        mat-raised-button
        type="submit"
      >
        {{ 'CHANGE MY PASSWORD' | translate }}
      </button>
    </form>
  </div>
</div>
