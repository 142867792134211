import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { secureStorage } from '../shared/functions/secure-storage';
import { GetLanguage } from '../shared/functions/shared-functions';
import { ConfigService } from '../../@config/config/config.service';
import { environment } from '../../environments/environment';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userData: any;
  isLoggedIn = false;
  membershipChecked: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getLocalStorageUser() {
    this.userData = secureStorage.getItem('userProfile');
    if (this.userData) {
      this.isLoggedIn = true;
      this.loggedIn.next(true);
      return true;
    } else {
      this.isLoggedIn = false;
      this.loggedIn.next(false);
      return false;
    }
  }

  logOut() {
    const lang = GetLanguage();
    secureStorage.clearAll();
    secureStorage.setItem('lang', lang);
    this.isLoggedIn = false;
    this.loggedIn.next(false);
    this.router.navigate(['/auth/login']);
  }

  isAuthorized() {
    return (
      secureStorage.getItem('userProfile') !== null &&
      secureStorage.getItem('userProfile') !== undefined
    );
  }

  setLocalUserProfile(value) {
    secureStorage.setItem('userProfile', value);
    this.configService.updateConfig({
      toolbar: {
        user: {
          visible: true,
        },
      },
    });
    this.getLocalStorageUser();
    this.isLoggedIn = true;
    this.loggedIn.next(true);
  }

  setToken(token) {
    secureStorage.setItem('token', token);
    secureStorage.setItem(
      'expire',
      moment().add(environment.idle_time_in_seconds, 'seconds'),
    );
  }

  getToken(token) {
    return secureStorage.getItem('token');
  }

  setRoles(roles) {
    const temp = [];
    roles.map((role) => {
      temp.push(role?.name);
    });
    secureStorage.setItem('rolesObjs', roles);
    secureStorage.setItem('roles', temp);
  }

  getRoles(): any {
    return secureStorage.getItem('roles');
  }

  handleError(error: HttpErrorResponse) {
    // // console.log('error', error)
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
