import { FormGroup } from '@angular/forms';
import 'moment/locale/ar-sa';
import 'moment/locale/en-au';

const CryptoJS = require('../../../assets/js/cryptoJs-local');

import { secureStorage } from './secure-storage';
import moment from 'moment';
import { datePipe, momentDateFormat } from '../variables/variables';
import { environment } from 'src/environments/environment';
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable';
import JsPDF from 'jspdf';
import { map } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';

export function getCurrentUserRoles(): any {
  return secureStorage.getItem('roles');
}

export function FilterRes(res: any) {
  if (Array.isArray(res)) {
    return res.filter(
      (item) => item?.isActive === true && item?.isDeleted === false,
    );
  } else {
    if (res?.isActive === true && res?.isDeleted === false) {
      return res;
    } else {
      return [];
    }
  }
}

export function FilterDeletedRes(res: any) {
  if (Array.isArray(res)) {
    return res.filter((item) => item?.isDeleted === false);
  } else {
    if (res?.isDeleted === false) {
      return res;
    } else {
      return [];
    }
  }
}

export function FilterLockups(res: any) {
  if (Array.isArray(res)) {
    return res.filter((item) => item.isDeleted === false);
  } else {
    if (res?.isDeleted === false) {
      return res;
    } else {
      return {};
    }
  }
}

export function GetLanguage() {
  return secureStorage.getItem('lang') || 'ar';
}

export function GetPreferredDate() {
  return secureStorage.getItem('userProfile')?.preferred_date?.toString();
}

export function GetCurrentUser() {
  return secureStorage.getItem('userProfile');
}

export function HandleListName(list) {
  list?.forEach(
    (item) =>
      (item.name =
        GetLanguage() === 'ar'
          ? item?.name_ar || item?.nameAr || item?.nameArabic
          : item?.name_en || item?.nameEn || item?.nameEng),
  );
  return list;
}

export function HandleListTitle(list) {
  list?.forEach(
    (item) =>
      (item.name = GetLanguage() === 'ar' ? item.title_ar : item.title_en),
  );
  return list;
}

export function prepareServerDate(date) {
  if (date) {
    return datePipe.transform(new Date(date), 'yyyy-MM-dd');
  } else {
    return null;
  }
}

export function HandleObjName(obj) {
  if (obj) {
    obj.name =
      GetLanguage() === 'ar'
        ? obj?.name_ar || obj?.nameAr
        : obj?.name_en || obj?.nameEn;
    return obj;
  }
  return;
}

export function GetDeviceInfoFromIPAddress() {
  // https://dashboard.ipregistry.co/keys
  fetch('https://api.ipregistry.co/?key=jn67t3hw1svfhqjr')
    .then(function (response) {
      return response.json();
    })
    .then(function (payload) {
      secureStorage.setItem('location', {
        country: payload?.location?.country?.name,
        city: payload?.location?.city,
      });
    });
}

export function HandleResponseError(error) {
  if (error?.error?.messageArEng) {
    if (GetLanguage() === 'ar') {
      return error.error.messageArEng[1];
    } else {
      return error.error.messageArEng[0];
    }
  } else if (error?.messageArEng) {
    if (GetLanguage() === 'ar') {
      return error.messageArEng[1];
    } else {
      return error.messageArEng[0];
    }
  } else {
    if (error?.error?.messageAr || error?.error?.messageEng) {
      if (GetLanguage() === 'ar') {
        return error?.error?.messageAr;
      } else {
        return error?.error?.messageEng;
      }
    } else {
      return 'An error has occurred, please try again later';
    }
  }
}

export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string,
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmedValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function ReloadCurrentComponent(router) {
  // https://www.codegrepper.com/code-examples/typescript/How+to+Reload+a+Component+in+Angular
  const currentUrl = router.url;
  router.routeReuseStrategy.shouldReuseRoute = () => false;
  router.onSameUrlNavigation = 'reload';
  router.navigate([currentUrl]);
}

export function searchInAllTableColumns(filterColumns, backup): any {
  return backup.filter((item) => {
    for (let i = 0; i < filterColumns.length; i++) {
      let check = false;
      if (
        item[filterColumns[i]?.type] &&
        item[filterColumns[i]?.type]
          ?.toString()
          .trim()
          .toLowerCase()
          .indexOf(filterColumns[i]?.value?.trim().toLowerCase()) !== -1
      ) {
        check = true;
      } else {
        return false;
      }
      if (i === filterColumns.length - 1) {
        return check;
      }
    }
  });
}

export function filterTable(e, backup) {
  let loopedArr;
  let data = backup;
  if (e?.sites?.length) {
    loopedArr = e.sites;
    data = loopOverFilter(loopedArr, 'site', data);
  }
  if (e?.departments?.length) {
    loopedArr = e.departments;
    data = loopOverFilter(loopedArr, 'department', data);
  }
  if (e?.individuals?.length) {
    loopedArr = e.individuals;
    data = loopOverFilter(loopedArr, 'employee', data);
  }
  if (e?.roles?.length) {
    loopedArr = e.roles;
    data = loopOverFilter(loopedArr, 'role', data);
  }
  if (e?.reportTypes?.length) {
    loopedArr = e.reportTypes;
    data = loopOverFilter(loopedArr, 'type', data);
  }
  if (e?.requestsStatus?.length) {
    loopedArr = e.requestsStatus;
    data = loopOverFilter(loopedArr, 'status', data);
  }
  if (e?.paw) {
    data = data.filter((x) => x.PAW === e.paw);
  }
  return data;
}

export function setMomentLocal() {
  // moment.locale(GetLanguage() === 'ar' ? 'ar-SA' : 'en-GB');
  moment.locale('en-GB');
}

function loopOverFilter(loopedArr, column, backup?) {
  const filtered = [];
  for (let i = 0; i < loopedArr.length; i++) {
    if (loopedArr[i]?.name?.trim().toLowerCase() === 'all') {
      return backup;
    }
    const batch = backup.filter(
      (x) =>
        x[column]?.toString().trim().toLowerCase() ===
        loopedArr[i]?.name?.trim().toLowerCase(),
    );
    batch?.forEach((one) => {
      if (filtered.findIndex((x) => x === one) === -1) {
        filtered.push(one);
      }
    });
  }
  return filtered;
}

export function AcceptEnglish(obj) {
  const event = obj.event;
  const pastedText = event.clipboardData?.getData('text');
  const code = pastedText ? pastedText?.charCodeAt(0) : event?.keyCode;
  const code2 = pastedText?.charCodeAt(1);
  const code3 = pastedText?.charCodeAt(6);
  const code4 = pastedText?.charCodeAt(12);
  const code5 = pastedText?.charCodeAt(17);
  if (
    (code >= 1536 && code <= 1791) ||
    (code2 >= 1536 && code2 <= 1791) ||
    (code3 >= 1536 && code3 <= 1791) ||
    (code4 >= 1536 && code4 <= 1791) ||
    (code5 >= 1536 && code5 <= 1791)
  ) {
    event.preventDefault();
    secureStorage.setItem('ArabicInEnglish', 'Please enter only english text.');
    if (!obj.control?.value) {
      obj.control.setErrors({ wrong_text: true });
    }
  } else {
    secureStorage.removeItem('ArabicInEnglish');
    obj.control.setErrors(null);
  }
}

export function AcceptArabic(obj) {
  const event = obj.event;
  const pastedText = event.clipboardData?.getData('text');
  const code = pastedText ? pastedText?.charCodeAt(0) : event?.keyCode;
  const code2 = pastedText?.charCodeAt(1);
  const code3 = pastedText?.charCodeAt(6);
  const code4 = pastedText?.charCodeAt(12);
  const code5 = pastedText?.charCodeAt(17);
  if (
    (code >= 65 && code <= 90) ||
    (code >= 97 && code <= 122) ||
    (code2 >= 65 && code2 <= 90) ||
    (code2 >= 97 && code2 <= 122) ||
    (code3 >= 65 && code3 <= 90) ||
    (code3 >= 97 && code3 <= 122) ||
    (code4 >= 65 && code4 <= 90) ||
    (code4 >= 97 && code4 <= 122) ||
    (code5 >= 65 && code5 <= 90) ||
    (code5 >= 97 && code5 <= 122)
  ) {
    event.preventDefault();
    secureStorage.setItem('EnglishInArabic', 'Please enter only arabic text.');
    if (!obj.control?.value) {
      obj.control.setErrors({ wrong_text: true });
    }
  } else {
    secureStorage.removeItem('EnglishInArabic');
    obj.control.setErrors(null);
  }
}

export function formatTimeFromString(value, format?) {
  if (value) {
    return moment(value, [moment.ISO_8601, 'HH:mm']);
  }
}

export function downloadPdfFileFromUrl(name, FileSaver, path, contentType) {
  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      const fileOfBlob = new File([this.response], name, { type: contentType });
      FileSaver.saveAs(fileOfBlob);
    }
  };
  xhr.open('GET', path);
  xhr.responseType = 'blob';
  xhr.send();
}

export function handleFilters(translateService, formData, type?) {
  let filterKeys = Object.keys(formData);
  filterKeys?.length && translateService.get(filterKeys).subscribe((res) => {
    // @ts-ignore
    filterKeys = Object.values(res);
  });
  // @ts-ignore
  const filterValues = Object.values(formData);
  const filters = [];
  filterKeys.map((key, index) => {
    filters.push({
      key: key,
      value: prepareValue(filterValues[index], type),
    });
  });
  return filters;
}

export async function ExportToPDF(
  translateService,
  headers,
  values,
  data,
  filterData?,
  subHeaders?,
  subValues?,
  titleInfo?
): Promise<any> {
  setMomentLocal();
  const filters = handleFilters(translateService, filterData, 'pdf');
  let currentTitle;
  const title = secureStorage.getItem('title');
  const customHeaders = [...headers];
  const customValues = [...values];
  let customSubHeaders = [];
  let customSubValues = [];
  const subHeader = [];
  const nestedTables = [
    /*'Evaluation details',*/ 'Actions on files status',
    'Actions on service request',
    'NOC audit',
    'Daily accounting',
    'Classification Report',
  ];
  currentTitle = prepareValue(
    translateService.instant(secureStorage.getItem('title')),
    'pdf',
  );
  if (nestedTables?.includes(title)) {
    customSubHeaders = [...subHeaders];
    customSubValues = [...subValues];
  }
  if (GetLanguage() === 'ar') {
    customHeaders.reverse();
    customValues.reverse();
    if (nestedTables?.includes(title)) {
      customSubHeaders.reverse();
      customSubValues.reverse();
    }
  }
  if (nestedTables?.includes(title)) {
    const head = translateService?.instant(customSubHeaders);
    Object.keys(head).map((el, index) => {
      const i = customHeaders.findIndex((x) => x == el);
      subHeader[i] = head[el];
    });
  }
  translateService.get(customHeaders).subscribe(async (mainHeader) => {
    const mainBody = [];
    data.forEach((e) => {
      const row = [];
      const temp = [];
      customValues.forEach((value) => {
        // column.push((e[value]?.toString()?.replace(')', '(').replace('(', ')')) || ' - ');
        row.push(e[value] || ' - ');
      });
      if (nestedTables?.includes(title)) {
        const firstNested =
          title === 'Evaluation details'
            ? e?.evaluationLsit
            : title === 'Actions on files status'
              ? e?.mainSubObject
              : title === 'NOC audit'
                ? e?.auditEntriesModelDetails
                : e?.mainSubObject;
        firstNested?.forEach((sub) => {
          const subRow = [];
          customSubValues.forEach((value) => {
            // column.push((e[value]?.toString()?.replace(')', '(').replace('(', ')')) || ' - ');
            subRow.push(sub[value] || ' - ');
          });
          temp.push(subRow);
        });
      }
      row.push(temp);
      mainBody.push(row);
    });
    const doc = new JsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    });
    doc.addFont('../assets/fonts/Amiri-Bold.ttf', 'Amiri', 'bold');
    doc.addFont('../assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');

    await addPropertiesToPdf(doc, filters, currentTitle, titleInfo);
    let counter = 0;
    const translatedHeader = [];
    Object.keys(mainHeader).map((el, index) => {
      const i = customHeaders.findIndex((x) => x == el);
      translatedHeader[i] = mainHeader[el];
    });
    autoTable(doc, {
      head: [translatedHeader],
      body: mainBody,
      // this startY to leave margon 350 from the first page on top
      startY: 350,
      // another way to put global margin and this will affect the first page only because
      // we will change the margin in didDrawPage function to every next page after the first one
      // margin: {top: 350},
      didDrawPage: function (data) {
        data.settings.startY = 40;
      },
      didDrawCell: function (nested) {
        const x = nested.row.index;
        const y = nested.row.raw['length'] - 1;
        if (nestedTables?.includes(title) && data && data?.length) {
          const bo = mainBody[x][y];
          // if (nested.column.index === Object.keys(mainHeader).length - 1) {
          if (
            nested.cell.section === 'body' &&
            nested.column.index === Object.keys(mainHeader).length - 1
          ) {
            //next condition :  for not adding subheader for total row Daily accounting report
            if (nested.row.index !== 0 || title !== 'Daily accounting') {
              nested.row.height = (nested.row.raw[y]['length'] + 1) * 45;
              autoTable(doc, {
                head: [subHeader],
                body: bo,
                // startY: (nested.cell.y + nested.row.height) * counter + 3,
                tableWidth: 'auto',
                theme: 'grid',
                showHead: 'firstPage',
                styles: { font: 'Amiri', minCellWidth: 14, halign: 'center' },
                bodyStyles: { minCellWidth: 50, fillColor: '#d0d0d2' },
                headStyles: {
                  fillColor: 'orange',
                  textColor: '#fff',
                  fontStyle: 'bold',
                },
                // margin: {top: 200}
                startY: nested.cell.y + 30,
                // margin: {left: nested.cell.x + nested.cell.padding('left')},
              });
            }
          }
        }
        counter++;
      },
      theme: 'grid',
      tableWidth: 'auto',
      pageBreak: 'auto',
      includeHiddenHtml: true,
      showHead: 'firstPage',
      styles: { font: 'Amiri', minCellWidth: 14, overflow: 'visible' },
      headStyles: {
        fillColor: '#0c35f8',
        textColor: '#fff',
        fontStyle: 'bold',
        halign: 'center',
      },
      bodyStyles: {
        minCellWidth: 50,
        fillColor: nestedTables?.includes(title) ? 'white' : 'white',
        cellPadding: { bottom: 5, top: 5 },
        valign: 'top',
        halign: 'center',
      },
    });
    const pdf = doc.save(
      `${currentTitle} - ${momentDateFormat(new Date())}.pdf`,
    );
    // CreatePdfFromHtml(secureStorage.getItem('title'), doc, `${currentTitle} - ${moment().format(dateFormat)}`);
    // mergeMultiPagesToPdf([doc], `${currentTitle} - ${momentDateFormat(new Date())}`, filters);
  });
}

async function addPropertiesToPdf(doc, filters, currentTitle, titleInfo) {
  doc.addFont('../assets/fonts/Amiri-Bold.ttf', 'Amiri', 'bold');
  doc.setFont('Amiri', 'bold');
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();
  const fontSize = 15;
  const url = '../assets/fonts/Tajawal-Bold.ttf';
  const imageUrl = environment.mainLogo;
  const fontBytes = await fetch(url).then((res) => res.arrayBuffer());
  const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
  // pdfDoc.registerFontkit(fontkit)
  // doc.addFont(url, 'Arimo', 'bold');
  const uint8View = new Uint8Array(imageBytes);
  doc.addImage({
    imageData: uint8View,
    x: width / 2 - 100,
    y: 20,
    width: 200,
    height: 150,
  });
  // const date = GetLanguage() === 'ar' ? new Date ().toLocaleDateString('ar-US' , {
  //   weekday: 'long',
  //   year: 'numeric',
  //   month: 'short',
  //   day: 'numeric',
  //  }) : momentDateFormat(new Date())
  const name = `${currentTitle} - ${momentDateFormat(new Date())}`;
  // const name = `${currentTitle} - ${date}`;
  const n =
    GetLanguage() === 'ar'
      ? prepareValue(name.split(' - ')[0])
      : name.split(' - ')[0];
  doc.setTextColor('#396fd4');
  doc.setFontSize(fontSize);
  let head = `${prepareValue(currentTitle, 'pdf')} - ${momentDateFormat(new Date())}`;
  if (titleInfo) {
    head = `${prepareValue(currentTitle, 'pdf')} ${titleInfo[0]["value"]}`;
  }
  doc.text(
    head,
    GetLanguage() === 'ar' ? width / 3 : 60,
    200,
  );
  doc.setTextColor('black');
  doc.setFontSize(10);
  let column1 = '';
  let column2 = '';
  let column3 = '';
  filters?.map((filter, index) => {
    const all = GetLanguage() === 'ar' ? 'الكل' : 'All';
    if (index <= filters?.length / 3) {
      column1 += `${filter?.key} :  ${getFilterValue(filter?.value) || all} \n`;
    } else if (index > filters?.length / 3 && index <= filters?.length / 1.5) {
      column2 += `${filter?.key} :  ${getFilterValue(filter?.value) || all} \n`;
    } else {
      column3 += `${filter?.key} :  ${getFilterValue(filter?.value) || all} \n`;
    }
  });
  doc.text(
    column1,
    GetLanguage() === 'ar' ? width - 120 : 60,
    GetLanguage() === 'ar' ? 230 : 230,
  );
  doc.text(
    column2,
    GetLanguage() === 'ar' ? width / 2.3 : 390,
    GetLanguage() === 'ar' ? 230 : 230,
  );
  doc.text(
    column3,
    GetLanguage() === 'ar' ? width / 5.2 : 720,
    GetLanguage() === 'ar' ? 230 : 230,
  );
}

function prepareValue(value, type?) {
  const emptyDate = GetLanguage() === 'ar' ? 'الكل' : 'All';
  if (value === '-') {
    return emptyDate;
  }
  if (type === 'pdf') {
    const english = new RegExp(/^[A-Za-z0-9]*$/);
    if (GetLanguage() === 'ar' && english.test(value)) {
      return value?.toString()?.split('').reverse().join('');
    }
    return GetLanguage() === 'ar'
      ? value?.toString()?.replace(')', '(')?.replace('(', ')')
      : value;
  } else {
    return value;
  }
}

export function getFilterValue(value) {
  if (GetLanguage() === 'ar' && value) {
    if (stringIsEnglish(value)) {
      // return value?.split('').reverse().join('');
      return value;
    } else {
      return value;
    }
  } else {
    return value;
  }
}

function stringIsEnglish(str) {
  const ENGLISH = {};
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    .split('')
    .forEach(function (ch) {
      ENGLISH[ch] = true;
    });
  let index;
  for (index = str.length - 1; index >= 0; --index) {
    if (ENGLISH[str.substring(index, index - 1)]) {
      return true;
    }
  }
}

export function closeAndBack() {
  window.close();
  opener.window.focus();
}

export function getListTotal(list) {
  return JSON.parse(list?.headers?.get('X-Pagination'))
    ? JSON.parse(list?.headers?.get('X-Pagination'))['TotalCount']
    : 0;
}

export function abbreviateNumber(count, withAbbr = true, decimals = 1) {
  const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  const num = parseFloat((count / Math.pow(1000, i)).toString());
  const fractions = num.toString().includes('.')
    ? parseFloat(
        (count / Math.pow(1000, i)).toString().split('.')[1]?.slice(0, 1),
      )
    : '';
  const number = num.toString().includes('.')
    ? parseFloat((count / Math.pow(1000, i)).toString().split('.')[0])
    : num;
  let result: any = fractions?.toString().length
    ? `${number}.${fractions}`
    : number;
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
}

export function addReviewColumn(columns) {
  const roles = getCurrentUserRoles();
  if (roles.includes('BoShowCandidateReview')) {
    columns?.push({
      label: 'Review',
      property: 'review',
      type: 'button',
      visible: true,
      cssClasses: ['text-secondary', 'w-10'],
    });
  }
}

export function addMenuColumn(columns) {
  columns?.push({
    label: '',
    property: 'menu',
    type: 'button',
    visible: true,
    cssClasses: ['text-secondary', 'w-10'],
  });
}

export function downloadFromBlobResponse(res, type, name) {
  const blob = new Blob([res], { type: type });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function getDropdownSettings(translate) {
  return {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: translate.instant('Select all'),
    unSelectAllText: translate.instant('UnSelect all'),
    itemsShowLimit: 3,
    allowSearchFilter: true,
    clearSearchFilter: true,
    searchPlaceholderText: translate.instant('Search ..'),
    noDataAvailablePlaceholderText: translate.instant('No data available'),
    noFilteredDataAvailablePlaceholderText: translate.instant(
      'No filtered data available',
    ),
  };
}

export function validateFileType(file): boolean {
  let valid = true;
  if (
    file?.type.includes('image/png') ||
    file?.type.includes('image/jpg') ||
    file?.type.includes('image/jpeg') ||
    file?.type.includes('image/bmp')
  ) {
    if (file?.size > 10000000) {
      this.translate
        .get('Only images less than 10M allowed')
        .subscribe((word) => {
          this.toastr.error(word);
        });
      valid = false;
    }
  } else {
    this.translate.get('Only images allowed').subscribe((word) => {
      this.toastr.error(word);
    });
    valid = false;
  }
  return valid;
}

async function scanFileVirus(body, http) {
  return await http
    .post(environment.scanFileUrl, body)
    .pipe(map((response) => response))
    .toPromise();
}

export async function scanFileToUpload(file, translate, toastr, http) {
  const scanFilesFormData = new FormData();
  scanFilesFormData.append('Password', environment.scanFilePassword);
  scanFilesFormData.append('file', file);
  try {
    await scanFileVirus(scanFilesFormData, http).then((res) => {
      if (res == 1) {
        return true;
      } else {
        toastr.error(translate.instant('scanFileError'));
        return false;
      }
    });
  } catch (error) {
    return false;
  }
}

export function openBrowserTab(link, id) {
  const a = document.querySelectorAll('.opener');
  const windows = secureStorage.getItem('opened_windows') || [];
  let i, opened_window;
  const coming_window = windows.find((x) => x.id === id);
  if (!coming_window || (coming_window && coming_window?.closed)) {
    opened_window = window.open(link, '_blank');
    windows.push({ id, window: opened_window });
    // secureStorage.setItem('opened_windows', windows)
    localStorage.setItem('opened_windows', windows.toString());
    opened_window.focus();
  } else {
    coming_window?.window.focus();
  }
}

export function GetEventMessage(
  event: HttpEvent<any>,
  globalService,
  name,
  message,
  fileType,
) {
  if (event.type === HttpEventType.DownloadProgress) {
    // Calculate the download progress percentage
    const progress = Math.round((100 * event.loaded) / event.total);
    globalService.progressMessage.next({ progress, message });
  } else if (event.type === HttpEventType.Response) {
    // File has been completely downloaded
    downloadFromBlobResponse(event.body, fileType, `${name}`);
    globalService.progressMessage.next({ loading: false });
  }
}

export function encrypt(msg, pass) {
  // random salt for derivation
  const keySize = 256;
  const salt = CryptoJS.lib.WordArray.random(16);
  // well known algorithm to generate key
  const key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: 100,
  });
  // random IV
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  // specify everything explicitly
  const encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  // combine everything together in base64 string
  const result = CryptoJS.enc.Base64.stringify(
    salt.concat(iv).concat(encrypted.ciphertext),
  );
  return result;
}
