import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class DecryptionService {
  private readonly passphrase: string = 'test pharse';
  private readonly salt: string = 'fixedSalt1234567';

  private generateKeyAndIv(passphrase: string): {
    key: CryptoJS.lib.WordArray;
    iv: CryptoJS.lib.WordArray;
  } {
    const keySize = 256 / 32; // AES-256
    const ivSize = 128 / 32; // Block size
    const iterations = 10000;

    const key = CryptoJS.PBKDF2(
      passphrase,
      CryptoJS.enc.Utf8.parse(this.salt),
      {
        keySize: keySize + ivSize,
        iterations: iterations,
        hasher: CryptoJS.algo.SHA256,
      },
    );

    return {
      key: CryptoJS.lib.WordArray.create(key.words.slice(0, keySize)),
      iv: CryptoJS.lib.WordArray.create(
        key.words.slice(keySize, keySize + ivSize),
      ),
    };
  }

  decrypt(encryptedData: string): string {
    const { key, iv } = this.generateKeyAndIv(this.passphrase);

    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
