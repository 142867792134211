import encUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';

declare let require: any;
const CryptoJS = require('crypto-js');
import { environment } from '../../../environments/environment';

const SecureStorage = require('secure-web-storage');

function getCurrentStorageType() {
  const rememberMe = String(localStorage.getItem('remember'));
  return JSON.parse(rememberMe) === false ? sessionStorage : localStorage;
}

const storage = new SecureStorage(getCurrentStorageType(), {
  hash: function hash(key: any) {
    key = CryptoJS.SHA256(key, environment.secret_key);

    return key?.toString();
  },
  encrypt: function encrypt(data: any) {
    data = CryptoJS.AES.encrypt(data, environment.secret_key);

    data = data?.toString();

    return data;
  },
  decrypt: function decrypt(data: any) {
    data = CryptoJS.AES.decrypt(data, environment.secret_key);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
});

export class secureStorage {
  private static currentStorage = storage;

  static refreshStorage() {
    try {
      this.currentStorage.storage = getCurrentStorageType();
    } catch (error) {}
  }

  static setItem(key: any, value: any): any {
    try {
      this.refreshStorage();
      this.currentStorage.setItem(key, value, { expiresIn: 10 });
    } catch (error) {}
  }

  static getItem(key: any): any {
    try {
      this.refreshStorage();
      return this.currentStorage.getItem(key);
    } catch (error) {
      return null;
    }
  }

  static removeItem(key: any): any {
    try {
      this.refreshStorage();
      return this.currentStorage.removeItem(key);
    } catch (error) {
      return null;
    }
  }

  static clearAll() {
    try {
      this.refreshStorage();
      this.currentStorage.clear();
      localStorage.clear();
      sessionStorage.clear();
    } catch (error) {}
  }

  static encryptItem(item: any) {
    return AES.encrypt(JSON.stringify(item), environment.secret_key).toString();
  }

  static decryptItem(item: any) {
    try {
      return JSON.parse(
        AES.decrypt(item, environment.secret_key).toString(encUTF8),
      );
    } catch (e) {
      // incorrect/missing secret, return the encrypted data instead
      return item;
    }
  }

  constructor() {
    // ls.config.encrypt = true;
  }
}
