import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProgressBarModule } from '../components/progress-bar/progress-bar.module';
import { SecondaryToolbarModule } from '../components/secondary-toolbar/secondary-toolbar.module';
import { CustomBreadcrumbsModule } from '../components/breadcrumbs/custom-breadcrumbs.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    ProgressBarModule,
    SecondaryToolbarModule,
    CustomBreadcrumbsModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
