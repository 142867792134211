import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

export class AutoComplete {
  myControl = new FormControl();
  constructor() {}

  displayFn(option: any): string {
    return option && option.name ? option.name : '';
  }

  _filter(name: string, options): any[] {
    const filterValue = typeof name === 'string' ? name.toLowerCase() : name;
    return options.filter((option) =>
      typeof option.name === 'string'
        ? option.name?.toLowerCase().includes(filterValue)
        : String(option.name).includes(filterValue),
    );
  }

  checkWrongError(controlIndex, control) {
    if (control?.value) {
      if (controlIndex === -1) {
        control.setErrors({ wrong: true });
      } else {
        control.setErrors(null);
      }
    }
  }

  fillAutocomplete(form, control, list) {
    return form.controls[control]?.valueChanges.pipe(
      startWith(''),
      map((value: any) => (typeof value === 'string' ? value : value?.name)),
      map((name: any) => (name ? this._filter(name, list) : list?.slice())),
    );
  }
}
