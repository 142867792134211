import { Component, OnInit } from '@angular/core';
import {
  GetCurrentUser,
  GetLanguage,
} from 'src/app/shared/functions/shared-functions';
import { CoreService } from '../../../services/core.service';
import { secureStorage } from 'src/app/shared/functions/secure-storage';
import { LocationStrategy } from '@angular/common';
import moment from 'moment';
import { dateTimeCompleteFormat } from '../../variables/variables';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'print-screen',
  templateUrl: './print-screen.component.html',
  styleUrls: ['./print-screen.component.scss'],
})
export class PrintScreenComponent implements OnInit {
  invoicesData: any[] = [];
  // displayedColumns contains list of the columns that used in Invoice Table.
  displayedColumns: string[] = ['serviceName', 'description'];
  printData: any = [];
  discountData;
  currentUser: any;
  paymentMethods: any[] = [];
  filteredPayment: any[] = [];
  cashierFullName: string;
  language = GetLanguage();
  printAr: boolean;
  data_id;
  loading = false;
  casheir_type;
  logo;
  action_code;

  constructor(
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: LocationStrategy,
  ) {
    // DISABLE BACK BUTTON (NOT TO SUBMIT SERVICE COLLECTION AGAIN)
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.data_id = secureStorage.decryptItem(
      this.activatedRoute.snapshot.queryParams?.id?.replaceAll(' ', '+'),
    );
    this.casheir_type = this.activatedRoute.snapshot.queryParams?.casheir_type;
    window.onafterprint = () => {
      // Your action after print is completed;
      this.navigateTopPrint();
    };
    this.logo =
      this.language === 'ar'
        ? 'assets/img/logos/arabic-logo-transparent.png'
        : 'assets/img/logos/english-logo-transparent.png';
    this.getData();
  }

  getData() {
    this.loading = true;
    this.coreService
      .getRequest(
        `Casher?CasherType=CaPosX2&auditPageCode=BoShowAccountingReceipts&id=${this.data_id}`,
      )
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        // this.printData = secureStorage.getItem('collectionData');
        // this.discountData = secureStorage.getItem('discountData');
        this.discountData = [res[0]];
        this.action_code = this.discountData[0]?.service?.actionCode;
        this.printData = res[0]?.personData;
        this.discountData[0]['fullName'] =
          this.discountData[0]?.user.firstName +
          ' ' +
          this.discountData[0]?.user.familyName;
        this.discountData[0]['serviceName'] =
          this.language === 'ar'
            ? this.discountData[0]?.service.nameAr
            : this.discountData[0]?.service.nameEn;
        if (this.discountData[0]?.receiptDate) {
          this.discountData[0]['receiptDate'] = moment(
            this.discountData[0]?.receiptDate,
          )
            ?.locale('en')
            .format(dateTimeCompleteFormat);
        }
        if (
          this.discountData[0]?.discount &&
          this.discountData[0]?.discount !== '0'
        ) {
          this.displayedColumns.push('discount');
          this.displayedColumns.push('total');
        } else {
          this.displayedColumns.push('total');
        }
        this.checkServiceType();
        this.handleData();
        this.printAr = this.language === 'ar';
        this.invoicesData = [
          {
            service:
              this.language === 'ar'
                ? this.discountData[0]?.service.nameAr
                : this.discountData[0]?.service.nameEn,
          },
        ];
        this.getPayment();
      });
  }

  handleData() {
    this.discountData.map(
      (item) =>
        (item.paymentMethod.name =
          this.language === 'ar'
            ? item.paymentMethod.nameAr
            : item.paymentMethod.nameEn),
    );
    if (!this.printData.arabicName) {
      this.printData.arabicName =
        this.printData?.firstNameAr +
        ' ' +
        (this.printData?.secondNameAr !== '-'
          ? this.printData?.secondNameAr
          : '') +
        ' ' +
        (this.printData?.thirdNameAr !== '-'
          ? this.printData?.thirdNameAr
          : '') +
        ' ' +
        this.printData?.familyNameAr;
      this.printData.englishName =
        this.printData?.firstNameEN +
        ' ' +
        (this.printData?.secondNameEN !== '-'
          ? this.printData?.secondNameEN
          : '') +
        ' ' +
        (this.printData?.thirdNameEN !== '-'
          ? this.printData?.thirdNameEN
          : '') +
        ' ' +
        this.printData?.familyNameEN;
    }
    this.printData.fullName =
      this.language === 'ar'
        ? this.printData.arabicName
        : this.printData.englishName;
    this.loading = false;
  }

  checkServiceType() {
    if (
      this.discountData[0]?.personMembership &&
      (this.action_code === 'RenewAnnualmembership' ||
        this.action_code === 'Annualmembership')
    ) {
      this.displayedColumns.push('numOfYears');
      if (this.discountData[0].personMembership.expiryDate) {
        this.displayedColumns.push('expiryDate');
        this.discountData[0].personMembership.expiryDate = moment(
          this.discountData[0].personMembership.expiryDate,
        )
          ?.locale('en')
          .format('yyyy-MM-DD');
      }
      if (this.discountData[0].personMembership.membershipNum) {
        this.displayedColumns.push('membershipNumber');
      }
    }

    if (
      this.action_code === 'ConvertFromAnnualtopermanent' ||
      this.discountData[0]?.service?.actionCode === 'permanentmembership'
    ) {
      this.displayedColumns.push('numOfYears');
      if (this.discountData[0].personMembership.membershipNum) {
        this.displayedColumns.push('membershipNumber');
      }
    }
    if (this.action_code === 'RenewAnnualmembership') {
      this.discountData[0].organization =
        GetLanguage() === 'ar'
          ? this.discountData[0]?.serviceRequest?.organization?.nameAr
          : this.discountData[0]?.serviceRequest?.organization?.nameEn;
    }
  }

  getPayment() {
    this.coreService.getRequest('PaymentMethod').subscribe((res) => {
      res = res.filter((el) => {
        return (
          el.isActive === true && el.isDeleted === false && el.isOline === false
        );
      });
      res?.map(
        (item) =>
          (item.name = this.language === 'ar' ? item.nameAr : item.nameEn),
      );
      if (!this.printData.putAction) {
        this.printData.paymentMethod = res.find(
          (x) => x.id === this.printData?.serviceRequest?.paymentMethodId,
        );
      }
    });
  }

  navigateTopPrint() {
    if (
      this.action_code === 'RenewAnnualmembership' ||
      this.action_code === 'ConvertFromAnnualtopermanent' ||
      this.action_code === 'permanentmembership' ||
      this.action_code === 'ReplacementMembershipCard' ||
      this.action_code === 'Annualmembership'
    ) {
      this.router.navigate(['/membership/membership-cards-list'], {
        queryParams: { passportNum: this.printData?.passportNum },
      });
    } else if (
      this.action_code === 'NOC' ||
      this.action_code === 'ToWhomConcernCertificateOutsideFee' ||
      this.action_code === 'ToWhomConcernCertificateFee'
    ) {
      this.router.navigate(['/issued-certificate-list'], {
        queryParams: { passportNum: this.printData?.passportNum },
      });
    } else if (
      this.action_code === 'IssuingSupervisoryEngCertSameOfficeFee' ||
      this.action_code === 'ToWhomConcernCertFeeProfessionalEng' ||
      this.action_code === 'ReplacementForLostProfessionalEngCertFee' ||
      this.action_code === 'ReplacementForLostConsultantEngCertFee' ||
      this.action_code === 'ReplacementForLostConsultantEngIdentity' ||
      this.action_code === 'ConsultantEngIdentityRenewalfee' ||
      this.action_code === 'ToWhomConcernCertFeeSupervisorEng'
    ) {
      this.router.navigate(
        ['/classification/classification-certificates-list'],
        { queryParams: { passportNum: this.printData?.passportNum } },
      );
    } else if (
      this.action_code === 'ConsultantEngIdentityRenewalfee' ||
      this.action_code === 'ReplacementForLostProfessionalEngIdentity' ||
      this.action_code === 'ProfessionalEngIdentityRenewalfee' ||
      this.action_code === 'ReplacementForLostSupervisorEngIdentity' ||
      this.action_code === 'SupervisorlEngIdentityRenewalfee' ||
      this.action_code === 'ReplacementForLostConsultantEngIdentity'
    ) {
      this.router.navigate(['/classification/classification-cards-list'], {
        queryParams: { passportNum: this.printData?.passportNum },
      });
    }
  }

  firePrint() {
    window.print();
  }

  back() {
    if (this.casheir_type === 'grouped') {
      this.router.navigate(['/grouped-cashier-list']);
    } else {
      this.router.navigate([`/${this.casheir_type}/cashier-list`]);
    }
  }
}
