import { Injectable } from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from '../interfaces/navigation-item.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { secureStorage } from '../../app/shared/functions/secure-storage';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  items: NavigationItem[] = [];
  pageIsList$ = new BehaviorSubject<boolean>(false);
  private urlAfterRedirects = new BehaviorSubject<any>(null);
  urlAfterRedirectsObservable$ = this.urlAfterRedirects.asObservable();
  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();
  parentUrl = '';
  fileStatuesSavedQuery = new BehaviorSubject<any>(false);
  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((item: any) => {
        this.pageIsList$.next(item?.urlAfterRedirects?.includes('list'));
      });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        untilDestroyed(this),
      )
      .subscribe((item: any) => {
        this.parentUrl = item?.url;
        this.urlAfterRedirects.next(item?.url);
        secureStorage.setItem('urlAfterRedirects', item?.url);
      });
  }

  getParent(api?) {
    const route = this.parentUrl?.split('/')[1];
    if (api) {
      return route?.slice(0, 1)?.toUpperCase()?.concat(route.slice(1));
    }
    return route;
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }
}
