import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { TableColumn } from 'src/@config/interfaces/table-column.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';
import { secureStorage } from 'src/app/shared/functions/secure-storage';
import {
  FilterRes,
  GetLanguage,
  getListTotal,
} from 'src/app/shared/functions/shared-functions';
import { GlobalService } from 'src/app/services/global.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PageNumber,
  PageSize,
  datePipe,
} from '../../../shared/variables/variables';

@UntilDestroy()
@Component({
  selector: 'issued-certificate-list',
  templateUrl: './issued-certificate-list.component.html',
  styleUrls: ['./issued-certificate-list.component.scss'],
})
export class IssuedCertificateListComponent implements OnInit {
  showTable = false;
  form: UntypedFormGroup;
  label;
  tableColumns: TableColumn<any>[] = [
    {
      label: '',
      property: 'selected',
      type: 'checkbox',
      visible: true,
      cssClasses: ['w-6'],
    },
    {
      label: 'File number',
      property: 'personDataId',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Arabic name',
      property: 'arabicName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'English name',
      property: 'englishName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Civil Id',
      property: 'civilID',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Nationality',
      property: 'nationalityL',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Passport num',
      property: 'passportNum',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Residency end date',
      property: 'residencyEndDate',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Certification type',
      property: 'certTypeName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'large-entry'],
    },
    {
      label: 'Issued certificate date',
      property: 'issueDate',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Print count',
      property: 'printCount',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary'],
    },
    {
      label: '',
      property: 'PrintCertificate',
      type: 'button',
      visible: true,
      cssClasses: ['text-secondary'],
    },
  ];
  queryParams;
  issuedCertificateData = [];
  total = 0;
  paginationEvent = {
    pageIndex: PageNumber,
    pageSize: PageSize,
    previousPageIndex: 0,
  };
  valuesGoingToFilter;

  constructor(
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    this.issuedCertificateData =
      this.activatedRoute.snapshot.data['IssuedCertificate']?.body;
    this.total = JSON.parse(
      this.activatedRoute.snapshot.data.IssuedCertificate?.headers.get(
        'X-Pagination',
      ),
    )['TotalCount'];
    this.handleTableObject(this.issuedCertificateData);
    this.subscribeToFilter();
    // passportNum coming from cashier after pay online and success to filter list
    const passportNum = this.activatedRoute.snapshot.queryParams.passportNum;
    this.valuesGoingToFilter = {
      issued_certificate_passportNum: passportNum,
    };
  }

  subscribeToFilter() {
    this.globalService.filterDataChangedSubject
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        this.queryParams = '';
        if (value) {
          if (value?.date_from) {
            const dateVal = datePipe.transform(
              new Date(value?.date_from).setHours(0, 0, 0, 0),
              'yyyy-MM-dd HH:mm:ss',
            );
            this.queryParams += `&${'DateFrom'}=${dateVal}`;
          }
          if (value?.date_to) {
            const dateVal = datePipe.transform(
              new Date(value?.date_to).setHours(0, 0, 0, 0),
              'yyyy-MM-dd HH:mm:ss',
            );
            this.queryParams += `&${'DateTo'}=${dateVal}`;
          }
          if (value?.receipt_Num) {
            this.queryParams += `${value?.receipt_Num ? '&ReceiptNo=' + value?.receipt_Num : ''}`;
          }
          if (value?.civil_ID) {
            this.queryParams += `${value?.civil_ID ? '&CiviLID=' + value?.civil_ID : ''}`;
          }
          if (value?.passport) {
            this.queryParams += `${value?.passport ? '&PassportNo=' + value?.passport : ''}`;
          }
          if (this.queryParams || value?.form_reset) {
            this.getServerData();
          }
        }
      });
  }

  resetPagination() {
    this.paginationEvent = {
      ...this.paginationEvent,
      pageIndex: PageNumber,
    };
  }

  getServerData(event?) {
    let pagination = '';
    let params = '';
    if (event?.pageIndex !== undefined) {
      this.paginationEvent = { ...event };
    } else if (event) {
      params = event;
    } else {
      this.resetPagination();
    }
    pagination = `PageSize=${this.paginationEvent.pageSize}&PageNumber=${this.paginationEvent.pageIndex}`;
    this.coreService
      .getRequest(
        `KSEIssuedCertificate?${pagination}${params}${
          this.queryParams ? this.queryParams : ''
        }`,
        {
          observe: 'response',
          responseType: 'json',
        },
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          const data = FilterRes(res.body);
          this.handleTableObject(data);
          this.total = getListTotal(res);
        },
        (err) => {
          // console.log('err' + err)
        },
      );
  }

  handleTableObject(res) {
    res = FilterRes(res);
    res?.map((item, index) => {
      item.arabicName =
        item.personData?.firstNameAr +
        ' ' +
        (item.personData?.secondNameAr !== '-'
          ? item.personData?.secondNameAr
          : '') +
        ' ' +
        (item.personData?.thirdNameAr !== '-'
          ? item.personData?.thirdNameAr
          : '') +
        ' ' +
        item.personData?.familyNameAr;
      item.englishName =
        item.personData?.firstNameEN +
        ' ' +
        (item.personData?.secondNameEN !== '-'
          ? item.personData?.secondNameEN
          : '') +
        ' ' +
        (item.personData?.thirdNameEN !== '-'
          ? item.personData?.thirdNameEN
          : '') +
        ' ' +
        item.personData?.familyNameEN;
      item.nationalityL =
        GetLanguage() === 'ar'
          ? item.nationality?.nameArabic
          : item?.nationality?.nameEng;
      item.civilID = item.personData?.civilID;
      item.passportNum = item.personData?.passportNum;
      item.residencyEndDate = item.personData?.residencyEndDate
        ? moment(item.personData?.residencyEndDate)?.format('DD/MM/YYYY')
        : '-';
      item.issueDate = item.issueDate
        ? moment(item.issueDate)?.format('DD/MM/YYYY')
        : '-';
      item.certTypeName =
        GetLanguage() === 'ar'
          ? item?.kseIssuedCertType?.nameAr
          : item?.kseIssuedCertType?.nameEn;
    });
    this.showTable = true;
    this.issuedCertificateData = res;
  }

  printCertificate(row) {
    if (row?.kseIssuedCertType?.actionCode === 'ToWhomeNeedCertNOC') {
      window.open(
        `/print-NOC-certificate?id=${secureStorage.encryptItem(row.id)}`,
        '_blank',
      );
    }
    window.open(
      `/print-certificate?id=${secureStorage.encryptItem(row.id)}`,
      '_blank',
    );
  }
}
