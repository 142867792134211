import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  template: ` <div
    class="flex items-center justify-content-center"
    style="min-height: 44px"
  >
    <!--      <app-breadcrumb>-->
    <!--        <a [routerLink]="['/']">-->
    <!--          <mat-icon svgIcon="mat:home" class="icon-sm"></mat-icon>-->
    <!--        </a>-->
    <!--      </app-breadcrumb>-->
    <!--      <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"-->
    <!--           style="width: 5px;margin-top: 7px;color: grey;height: 5px;"></div>-->
    <lib-breadcrumbs class="breadcrumb"></lib-breadcrumbs>
  </div>`,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
