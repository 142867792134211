<page-container [hasFilter]="false" [listName]="''" [hasGrid]="false">
  <div class="p-0" id="print-section" *ngIf="discountData?.length; else loader">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="center stretch"
      style="width: 100%; height: 100%"
      [class]="printAr ? 'pRTL' : 'pLTR'"
    >
      <div class="w-100">
        <mat-card class="invoice wrap mat-pad-none position-relative">
          <div *ngIf="printData.putAction" class="waterMark">
            {{ 'Copy' | translate }}
          </div>
          <div
            class="invoice-header"
            fxLayout="row wrap"
            fxLayoutAlign="space-between center"
          >
            <div
              class="note-wrap d-flex"
              style="margin: 10px 10px; font-style: italic; font-size: 13px"
            >
              <span class="gene-block" style="margin: 0 5px; font-size: 12px"
                >{{ 'Cashier name' | translate }}:
                <span style="font-weight: 700"
                  >{{ discountData[0]?.fullName }} -
                  <span
                    dir="ltr"
                    style="
                      font-weight: 700;
                      font-size: 13px;
                      font-style: normal;
                    "
                    >#{{ discountData[0]?.user.id }}</span
                  ></span
                ></span
              >
            </div>
            <div
              class="print-wrap text-center bdr-left cursor-pointer d-flex gap-2 justify-content-evenly"
              style="padding: 14px 0; position: relative"
              fxFlex.gt-xs="176px"
            >
              <button
                color="primary"
                mat-flat-button
                type="submit"
                class="print-button"
                (click)="firePrint()"
              >
                {{ 'Print' | translate }}
              </button>
              <button
                *ngIf="casheir_type"
                color="accent"
                mat-flat-button
                type="button"
                class="print-button"
                (click)="back()"
              >
                {{ 'go_to_list' | translate }}
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="invoice-content-wrap" style="padding: 25px">
            <div
              class="title-sec mrgn-b-sm"
              fxLayout="row wrap"
              style="justify-content: space-between"
            >
              <div class="invoice-meta text-right" style="width: 200mm">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <p class="mrgn-b-sm">
                    {{ 'Receipt number' | translate }}:
                    <span
                      dir="ltr"
                      style="font-weight: 600"
                      class="font-bold"
                      >{{ discountData[0]?.receiptNum }}</span
                    >
                  </p>
                  <p
                    style="direction: ltr !important; font-weight: 600"
                    class="font-bold mrgn-b-sm"
                  >
                    {{ discountData[0]?.receiptDate }}
                  </p>
                </div>
                <div
                  class="address-wrap"
                  style="text-align: start"
                  fxLayoutAlign="space-between"
                >
                  <div class="to-addr info-cont bdr-all">
                    <div class="sec-content">
                      <h6 class="font-bold mrgn-b-sm">
                        {{ printData?.fullName }}
                      </h6>
                      <span
                        *ngIf="printData?.civilID"
                        class="gene-block mrgn-b-sm"
                      >
                        {{ 'Civil ID' | translate }}:
                        <span
                          style="font-weight: 600"
                          class="font-bold mrgn-b-sm"
                          >{{ printData?.civilID }}</span
                        >
                      </span>
                      <span class="gene-block mrgn-b-sm"
                        >{{ 'Passport num' | translate }}:
                        <span
                          dir="ltr"
                          style="font-weight: 600"
                          class="font-bold mrgn-b-sm"
                          >{{ printData?.passportNum }}</span
                        ></span
                      >
                    </div>
                  </div>
                  <div class="to-addr info-cont mrgn-b-md bdr-all">
                    <div class="sec-content">
                      <h6 class="font-bold mrgn-b-sm">
                        {{ 'Payment method' | translate }}
                      </h6>
                      <span class="gene-block mrgn-b-sm">
                        {{ 'Type' | translate }}:
                        <span
                          style="font-weight: 600"
                          class="font-bold mrgn-b-sm"
                          >{{ discountData[0]?.paymentMethod?.name }}</span
                        >
                      </span>
                      <span
                        *ngIf="discountData[0]?.referenceNumber"
                        class="gene-block mrgn-b-sm"
                      >
                        <span class="mrgn-b-sm"
                          >{{ 'Reference number' | translate }}:
                        </span>
                        {{ discountData[0]?.referenceNumber }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="invoice-table-wrap bdr-all">
              <table class="full-wid" mat-table [dataSource]="invoicesData">
                <ng-container
                  matColumnDef="serviceName"
                  style="min-width: 100px !important"
                >
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Service' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0]?.serviceName }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Amount' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0]?.price }}
                    {{ 'K.D' | translate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="discount">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Discount' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0]?.discount }} {{ 'K.D' | translate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="total">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Total price' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0]?.totalPrice }} {{ 'K.D' | translate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="numOfYears">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Num of years' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0].personMembership.numOfYears }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="expiryDate">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Membership expiry date' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0].personMembership.expiryDate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="membershipNumber">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Membership number' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0].personMembership.membershipNum }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="organization">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Organization' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ discountData[0].organization }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</page-container>
<ng-template #loader>
  <div class="col-sm-12 justify-content-center align-items-center d-flex">
    <!--    <div class="spinner-border loader">-->
    <!--      <span class="loading__anim" id=""></span>-->
    <!--    </div>-->
    <div class="dotted-loader"></div>
  </div>
</ng-template>
