import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigModule } from '../@config/config.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CustomLayoutModule } from './core/components/layout/custom-layout/custom-layout.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './services/interceptor';
import {
  GetCurrentUser,
  GetLanguage,
  GetPreferredDate,
} from './shared/functions/shared-functions';
import { getDutchPaginatorIntl } from './shared/functions/dutch-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingGuard } from './core/guards/landing.guard';
import { ChangePasswordComponent } from './modules/authentication/change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SideNavItems } from '../@config/sidenav/sidenav-items';
import { ViewPermissionsComponent } from './modals/view-permissions/view-permissions.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WidgetComponentModule } from './modules/widget-component/widget-component.module';

// import {DateAdapter} from "angular-calendar";
// import {
//   MAT_MOMENT_DATE_ADAPTER_OPTIONS,
//   MAT_MOMENT_DATE_FORMATS,
//   MomentDateAdapter
// } from "@angular/material-moment-adapter";

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import 'moment/locale/ar-sa';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { BreadcrumbTranslateService } from './services/breadcrumb-translate.service';
import { SocketIo } from './services/sokcet-io.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { MailInboxComponent } from './modules/reception/mail-inbox/mail-inbox.component';
import { PageTitleService } from './core/components/page-title/page-title.service';
import { TableRowDataComponent } from './modals/table-row-data/table-row-data.component';
import { ReviewGradesComponent } from './modals/review-grades/review-grades.component';
import { GroupedCashierComponent } from './modules/grouped-cashier/grouped-cashier.component';
import { ViewMessageDetailsComponent } from './modals/view-message-details/view-message-details.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ApiInterceptor } from './services/ApiInterceptor';

const config: SocketIoConfig = {
  url: `${environment.socketUrl}?user_id=${GetCurrentUser()?.unique_id}`,
  options: {
    transports: ['websocket'],
  },
};

export const MyDefaultTooltipOptions: TooltipOptions = {
  'show-delay': 0,
  hideDelay: 0,
};
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    MailInboxComponent,
    ViewPermissionsComponent,
    TableRowDataComponent,
    ReviewGradesComponent,
    GroupedCashierComponent,
    ViewMessageDetailsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    MatDividerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    WidgetComponentModule,
    // Vex
    ConfigModule,
    CustomLayoutModule,
    ToastrModule.forRoot({
      // maxOpened: 1,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TooltipModule.forRoot(MyDefaultTooltipOptions),
    ReactiveFormsModule,
    FlexModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgxIntlTelInputModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    FormsModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule.setLocale(
      GetLanguage() === 'ar'
        ? GetPreferredDate() === '1'
          ? 'ar-EG'
          : 'ar-sa'
        : 'en-GB',
    ),
    BreadcrumbsModule.forRoot({
      postProcess: null,
      applyDistinctOn: 'text',
    }),
    SocketIoModule.forRoot(config),
    NgIdleKeepaliveModule.forRoot(), // use NgIdleModule.forRoot() if not using keepalive
  ],
  providers: [
    SideNavItems,
    AuthService,
    BreadcrumbTranslateService,
    SocketIo,
    PageTitleService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    AuthGuard,
    LandingGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
