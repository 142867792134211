import { mergeDeep } from '../utils/merge-deep';
import { AppConfigName } from './config-name.model';
import { AppConfig } from './app-config.interface';
import { ColorSchemeName } from './colorSchemeName';
import { colorVariables } from '../components/config-panel/color-variables';

const defaultConfig: AppConfig = {
  id: AppConfigName.apollo,
  name: 'Apollo',
  style: {
    colorScheme: ColorSchemeName.default,
    colors: {
      primary: colorVariables.blue,
    },
    borderRadius: {
      value: 0.25,
      unit: 'rem',
    },
    button: {
      borderRadius: undefined,
    },
  },
  direction: 'ltr',
  imgSrc: '//vex-landing.visurel.com/assets/img/layouts/apollo.png',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'Kuwait Society of Engineers',
    imageUrl: 'assets/img/demo/logo.svg',
    showCollapsePin: true,
    user: {
      visible: true,
      avatar: '',
    },
    search: {
      visible: true,
    },
    state: 'collapsed',
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true,
    },
  },
  navbar: {
    position: 'below-toolbar',
  },
  footer: {
    visible: true,
    fixed: true,
  },
  crumbChildren: [],
  currentCrumb: '',
};

export const configs: AppConfig[] = [
  defaultConfig,
  mergeDeep(
    { ...defaultConfig },
    {
      id: AppConfigName.poseidon,
      name: 'Poseidon',
      imgSrc: '//vex-landing.visurel.com/assets/img/layouts/poseidon.png',
      style: {
        borderRadius: {
          value: 0.5,
          unit: 'rem',
        },
        button: {
          borderRadius: {
            value: 9999,
            unit: 'px',
          },
        },
      },
      sidenav: {
        user: {
          visible: true,
          avatar: 'assets/img/avatars/avatar.jpeg',
        },
        search: {
          visible: false,
        },
      },
      toolbar: {
        user: {
          visible: false,
        },
      },
      footer: {
        fixed: false,
      },
      crumbChildren: [],
      currentCrumb: '',
    },
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: AppConfigName.hermes,
      name: 'Hermes',
      imgSrc: '//vex-landing.visurel.com/assets/img/layouts/hermes.png',
      layout: 'vertical',
      boxed: true,
      sidenav: {
        user: {
          visible: false,
          avatar: 'assets/img/avatars/avatar.jpeg',
        },
        search: {
          visible: false,
        },
      },
      toolbar: {
        fixed: false,
      },
      footer: {
        fixed: false,
      },
      crumbChildren: [],
      currentCrumb: '',
    },
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: AppConfigName.ares,
      name: 'Ares',
      imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ares.png',
      sidenav: {
        user: {
          visible: false,
          avatar: 'assets/img/avatars/avatar.jpeg',
        },
        search: {
          visible: false,
        },
      },
      toolbar: {
        fixed: false,
      },
      navbar: {
        position: 'in-toolbar',
      },
      footer: {
        fixed: false,
      },
      crumbChildren: [],
      currentCrumb: '',
    },
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: AppConfigName.zeus,
      name: 'Zeus',
      imgSrc: '//vex-landing.visurel.com/assets/img/layouts/zeus.png',
      sidenav: {
        state: 'collapsed',
      },
    },
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: AppConfigName.ikaros,
      name: 'Ikaros',
      imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ikaros.png',
      layout: 'vertical',
      boxed: true,
      sidenav: {
        user: {
          visible: false,
          avatar: 'assets/img/avatars/avatar.jpeg',
        },
        search: {
          visible: false,
        },
      },
      toolbar: {
        fixed: false,
      },
      navbar: {
        position: 'in-toolbar',
      },
      footer: {
        fixed: false,
      },
      crumbChildren: [],
      currentCrumb: '',
    },
  ),
];
