import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ng2-tooltip-directive';
import { DeleteComponent } from '../modals/delete/delete.component';
import { LoaderComponent } from './component/loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableComponent } from './component/data-table/data-table.component';
import { MatSortModule } from '@angular/material/sort';
import { ScrollbarModule } from '../../@config/components/scrollbar/scrollbar.module';
import { ExcelService } from '../services/excel.service';
import { PageLayoutModule } from 'src/@config/components/page-layout/page-layout.module';
import { CustomBreadcrumbsModule } from 'src/@config/components/breadcrumbs/custom-breadcrumbs.module';
import { PhoneWithCountryComponent } from './component/phone-with-country/phone-with-country.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FilterComponent } from './component/filter/filter.component';
import { DateFormatPipe } from '../pipes/date-formate.pipe';
import { ArabicInEnglishError } from '../pipes/arabic-in-english-error';
import { EnglishInArabicErrorPipe } from '../pipes/english-in-arabic-error.pipe';
import { CalendarPipe } from '../pipes/calendar.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DataGridComponent } from './component/data-grid/data-grid.component';
import { GridCardComponent } from './component/grid-card/grid-card.component';
import { PageContainerComponent } from '../../@config/components/page-container/page-container.component';
import { TermsConditionsComponent } from './component/terms-conditions/terms-conditions.component';
import { AccreditResultsListComponent } from './component/accredit-results/accredit-results-list/accredit-results-list.component';
import { AddExaminerComponent } from './component/add-panel-examiner/add-examiner/add-examiner.component';
import { EvaluationListComponent } from './component/evaluation/evaluation-list.component';
import { ResultsListComponent } from './component/results/results-list/results-list.component';
import { CashierComponent } from '../modules/accounting/cashier/cashier.component';
import { AcceptanceComponent } from '../modules/review/final-review/acceptance/acceptance.component';
import { PanelExaminersListComponent } from './modules/panel-examiners/panel-examiners-list/panel-examiners-list.component';
import { ReEvaluateListComponent } from './component/re-evaluate/re-evaluate-list/re-evaluate-list.component';
import { RemoveExaminerListComponent } from './component/remove-examiner/remove-examiner-list/remove-examiner-list.component';
import { NgMultiselectDropdown3Module } from 'ng-multiselect-dropdown3';
import { ImageViewerComponent } from './component/image-viewer/image-viewer.component';
import { ServiceCollectionComponent } from './component/service-collection/service-collection.component';
import { PrintScreenComponent } from './component/print-screen/print-screen.component';
import { ProgressMessageComponent } from './component/progress-message/progress-message.component';

@NgModule({
  declarations: [
    LoaderComponent,
    DataTableComponent,
    DeleteComponent,
    PhoneWithCountryComponent,
    FilterComponent,
    DateFormatPipe,
    ArabicInEnglishError,
    EnglishInArabicErrorPipe,
    CalendarPipe,
    DataGridComponent,
    GridCardComponent,
    PageContainerComponent,
    TermsConditionsComponent,
    AccreditResultsListComponent,
    AddExaminerComponent,
    EvaluationListComponent,
    ResultsListComponent,
    CashierComponent,
    AcceptanceComponent,
    PanelExaminersListComponent,
    ReEvaluateListComponent,
    RemoveExaminerListComponent,
    ImageViewerComponent,
    ServiceCollectionComponent,
    PrintScreenComponent,
    ProgressMessageComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTableComponent,
    DeleteComponent,
    LoaderComponent,
    TooltipModule,
    PageLayoutModule,
    PhoneWithCountryComponent,
    FilterComponent,
    DateFormatPipe,
    ArabicInEnglishError,
    EnglishInArabicErrorPipe,
    CalendarPipe,
    PageLayoutModule,
    CustomBreadcrumbsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    TooltipModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    RouterModule,
    MatRippleModule,
    TooltipModule,
    TranslateModule,
    MatSortModule,
    ScrollbarModule,
    NgxIntlTelInputModule,
    FlexLayoutModule,
    DataGridComponent,
    PageContainerComponent,
    AccreditResultsListComponent,
    AddExaminerComponent,
    EvaluationListComponent,
    ResultsListComponent,
    CashierComponent,
    AcceptanceComponent,
    PanelExaminersListComponent,
    ReEvaluateListComponent,
    RemoveExaminerListComponent,
    ImageViewerComponent,
    ServiceCollectionComponent,
    PrintScreenComponent,
    ProgressMessageComponent,
  ],
  imports: [
    PageLayoutModule,
    CustomBreadcrumbsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    TooltipModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    RouterModule,
    MatRippleModule,
    TooltipModule,
    TranslateModule,
    MatSortModule,
    ScrollbarModule,
    NgxIntlTelInputModule,
    FlexLayoutModule,
    NgMultiselectDropdown3Module,
  ],
  providers: [
    ExcelService,
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
