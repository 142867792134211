<div class="h-full flex flex-col">
  <div
    *ngIf="data?.length > 0; else noData"
    @stagger
    class="overflow-y-auto flex-auto"
  >
    <div
      class="p-gutter grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 bg-grey"
    >
      <app-grid-card
        (openRowOuter)="openRow($event)"
        *ngFor="let item of data"
        @fadeInUp
        [item]="item"
      ></app-grid-card>
    </div>
  </div>

  <ng-template
    #noData
    class="flex-auto flex flex-col items-center justify-center"
  >
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg" />
    <h2 class="headline m-0 text-center">
      {{ 'No data available' | translate }}
    </h2>
  </ng-template>
</div>
