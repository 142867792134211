import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { GlobalService } from '../../../app/services/global.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnChanges {
  @Input() hasFilter: boolean = false;
  @Input() listName: string;
  @Input() valuesGoingToFilter: any;
  @Input() hasGrid: boolean;
  @Input() filterOpened: boolean;
  @Input() downloadReportPdf: boolean;
  @Input() downloadReportExcel: boolean;
  @Output() filterTable = new EventEmitter<any>();
  @Output() downloadPdfEvent = new EventEmitter<any>();
  @Output() downloadExcelEvent = new EventEmitter<any>();

  constructor(private globalService: GlobalService) {}

  ngOnChanges() {
    this.globalService.listEvent.next({
      filterOpened: this.filterOpened,
      hasFilter: this.hasFilter,
      hasGrid: this.hasGrid,
      valuesGoingToFilter: this.valuesGoingToFilter,
      downloadReportPdf: this.downloadReportPdf,
      downloadReportExcel: this.downloadReportExcel,
      listName: this.listName,
    });
  }
}
