import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { DecryptionService } from './decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  baseUrl = environment.apiUrl;
  reloadFormWizard: Subject<boolean> = new Subject();

  userNames: BehaviorSubject<boolean> = new BehaviorSubject(false);
  deleteRoleFromGroup: Subject<any> = new Subject();

  constructor(
    private matDialog: MatDialog,
    private decryptionService: DecryptionService,
    private http: HttpClient,
  ) {}

  postRequest(path, body, observe?): Observable<any> {
    return this.http
      .post(`${this.baseUrl}${path}`, body, {
        ...observe,
        responseType: this.getResponseType(path),
      })
      .pipe(
        map((event) => {
          return this.handleResponse(event, path);
        }),
        catchError((error) => {
          return throwError(this.handleError(error, path));
        }),
      );
  }

  getRequest(path, observe?): Observable<any> {
    return this.http
      .get(`${this.baseUrl}${path}`, {
        ...observe,
        responseType: this.getResponseType(path),
      })
      .pipe(
        map((event) => {
          return this.handleResponse(event, path);
        }),
        catchError((error) => {
          return throwError(this.handleError(error, path));
        }),
      );
  }

  getRequestWithBody(path, body?): Observable<any> {
    return this.http
      .request('Get', `${this.baseUrl}${path}`, {
        body,
        responseType: this.getResponseType(path),
      })
      .pipe(
        map((event) => {
          return this.handleResponse(event, path);
        }),
        catchError((error) => {
          return throwError(this.handleError(error, path));
        }),
      );
  }

  putRequest(path, body): Observable<any> {
    return this.http
      .put(`${this.baseUrl}${path}`, body, {
        responseType: this.getResponseType(path) as any,
      })
      .pipe(
        map((event) => {
          return this.handleResponse(event, path);
        }),
        catchError((error) => {
          return throwError(this.handleError(error, path));
        }),
      );
  }

  deleteRequest(path): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}${path}`, {
        responseType: this.getResponseType(path) as any,
      })
      .pipe(
        map((event) => {
          return this.handleResponse(event, path);
        }),
        catchError((error) => {
          return throwError(this.handleError(error, path));
        }),
      );
  }

  handleResponse(event, path): Observable<any> {
    // in case response of status code 204 which has no content (event = null) angular consider it as an error and goes in catchError function
    // this way to handle it and prevent it from going to catchError function
    if (!event) {
      return of(null);
    }
    return this.willDecrypt(path) ? this.decryptResponse(event) : event;
  }

  handleError(error, path): any {
    // Handle the error here
    console.error('An error occurred:', error);
    // Optionally, you can transform the error or rethrow it
    error.error = this.willDecrypt(path)
      ? this.decryptResponse(error.error)
      : error.error;
    return error;
  }

  willDecrypt(path): boolean {
    return environment.encryptedResponse && !path.includes('CreatePdf');
  }

  getResponseType(path) {
    return environment.encryptedResponse && !path.includes('CreatePdf')
      ? 'text'
      : 'json';
  }

  setUserNames(value) {
    this.userNames.next(value);
  }

  deleteRole(obj) {
    this.deleteRoleFromGroup.next(obj);
  }

  reloadWizard() {
    this.reloadFormWizard.next(true);
  }

  private validateJson(data: string): boolean {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      console.error('Invalid JSON format', error);
      return false;
      // throw new Error('Invalid JSON format');
    }
  }

  public decryptResponse(event) {
    const body = event.body ? event.body : event;
    try {
      const decryptedBody = this.decryptionService.decrypt(body);
      if (decryptedBody) {
        // receiving a JSON response even if it json direct body response or HTTPResponse like that {header, body, url}
        if (this.validateJson(decryptedBody)) {
          return event.body
            ? event.clone({ body: JSON.parse(decryptedBody) })
            : JSON.parse(decryptedBody);
        } else {
          // receiving string response
          return decryptedBody;
        }
      } else {
        return event;
      }
    } catch (error) {
      return event;
    }
  }
}
