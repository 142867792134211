import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalService } from '../../../services/global.service';

@UntilDestroy()
@Component({
  selector: 'app-progress-message',
  templateUrl: './progress-message.component.html',
  styleUrls: ['./progress-message.component.scss'],
})
export class ProgressMessageComponent implements OnInit {
  loading;
  progress;
  message;

  constructor(private globalService: GlobalService) {}

  ngOnInit() {
    this.globalService.progressMessageObservable$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          if (res?.progress || res?.progress == 0) {
            this.progress = res.progress;
            this.message = res.message;
          } else {
            if (!res?.loading) {
              this.message = this.progress = null;
            }
            this.loading = res?.loading;
          }
        }
      });
  }
}
