import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { GetLanguage } from '../../../app/shared/functions/shared-functions';
import { CoreService } from '../../../app/services/core.service';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(
    map((config) => config.sidenav.title),
  );
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl),
  );
  showCollapsePin$ = this.configService.config$.pipe(
    map((config) => config.sidenav.showCollapsePin),
  );
  userVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.user.visible),
  );
  userAvatar$ = this.configService.config$.pipe(
    map((config) => config.sidenav.user.avatar),
  );
  searchVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.search.visible),
  );

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;
  mainLogo =
    GetLanguage() === 'ar'
      ? environment.mainLogo_arabic_white
      : environment.mainLogo_english_white;
  // mainLogo = '../../../assets/img/logos/Logo.png';
  mainLogoSmall = environment.small_logo;

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    public coreService: CoreService,
    private readonly popoverService: PopoverService,
    private readonly dialog: MatDialog,
  ) {
    this.configService.updateConfig({
      sidenav: {
        imageUrl: this.mainLogo,
      },
    });
  }

  ngOnInit() {
    // this.toggleCollapse();
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ],
      }),
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  checkChildren(childitem) {
    if (childitem?.route?.includes('add-engineer')) {
      this.coreService.reloadWizard();
    }
  }
}
