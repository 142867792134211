import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Scroll,
} from '@angular/router';
import { LoaderService } from '../../../services/loader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isPageLoading = false;
  isXHRLoading = false;
  loadText: any;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => this.checkRouterEvent(event));

    this.loaderService.loaderState
      .pipe(untilDestroyed(this))
      .subscribe((loader) =>
        setTimeout(() => {
          this.isXHRLoading = loader.show;
          this.loadText = loader.text;
        }),
      );
  }

  isLoading() {
    return this.isPageLoading || this.isXHRLoading;
  }

  checkRouterEvent(routerEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.isPageLoading = true;
    }

    if (
      routerEvent instanceof Scroll &&
      routerEvent?.routerEvent instanceof NavigationEnd
    ) {
      this.isPageLoading = false;
      this.loaderService.hide();
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.isPageLoading = false;
    }
  }
}
