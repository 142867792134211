import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../services/core.service';
import { FilterRes, GetLanguage } from '../../functions/shared-functions';

@Component({
  selector: 'ms-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements OnInit {
  pageContent: any[] = [];
  constructor(private CoreService: CoreService) {}

  ngOnInit(): void {
    this.CoreService.getRequest(
      'FoPageContent?PageCode=TermsConditions',
    ).subscribe((res) => {
      this.pageContent = FilterRes(res);
      this.pageContent
        .sort((o1, o2) => o1.order - o2.order)
        .map((item) => {
          item.content =
            GetLanguage() === 'ar' ? item.contentAr : item.contentEng;
          item.title = GetLanguage() === 'ar' ? item.titleAr : item.titleEng;
        });
    });
  }
}
