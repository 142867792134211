<div
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 hover:bg-hover"
  matRipple
>
  <div
    class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block"
  >
    {{ userData?.firstName + userData?.familyName }}
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-light-grey"
  >
    <mat-icon
      *ngIf="!userData?.userImage || userImageError"
      svgIcon="mat:person"
      style="fill: var(--primary-color)"
    ></mat-icon>
    <img
      class="user_img"
      (error)="userImageError = true"
      *ngIf="userData?.userImage && !userImageError"
      src="{{ userData?.userImage }}"
    />
  </div>
</div>
