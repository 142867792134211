<div class="card overflow-hidden">
  <div
    (click)="openRow()"
    class="p-4 text-center hover:bg-hover trans-ease-out cursor-pointer relative"
    matRipple
  >
    <img
      [src]="item?.imageSrc || defaultImage"
      class="avatar h-24 w-24 mx-auto"
      alt="user-image"
    />

    <h2 class="title mb-1 mt-3">
      {{ item?.firstName + ' ' + item?.familyName }}
    </h2>
    <div class="body-2 text-secondary flex items-center justify-center">
      <mat-icon
        class="ltr:mr-1 rtl:ml-1 icon-xs"
        svgIcon="mat:business"
      ></mat-icon>
      <span>{{ item?.company }}</span>
    </div>

    <div class="body-2 text-secondary flex items-center justify-center">
      <mat-icon
        class="ltr:mr-1 rtl:ml-1 icon-xs"
        svgIcon="mat:phone"
      ></mat-icon>
      <span>{{ item?.phone }}</span>
    </div>
  </div>

  <div class="bg-app-bar p-2 flex items-center justify-around">
    <a
      href="{{ 'tel:' + item?.phone }}"
      class="text-secondary"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:phone"></mat-icon>
    </a>

    <a
      href="{{ 'mailto:' + item?.email }}"
      class="text-secondary"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:mail"></mat-icon>
    </a>

    <button class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:chat"></mat-icon>
    </button>
  </div>
</div>
