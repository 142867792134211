import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
} from '../../../interfaces/navigation-item.interface';
import { dropdownAnimation } from '../../../animations/dropdown.animation';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import {
  getCurrentUserRoles,
  GetLanguage,
  HandleResponseError,
} from '../../../../app/shared/functions/shared-functions';
import { CoreService } from '../../../../app/services/core.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../config/config.service';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Component({
  selector: 'sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavItemComponent implements OnInit, OnChanges {
  roles = getCurrentUserRoles();
  @Input() item: NavigationItem;
  @Input() level: number;
  isOpen: boolean;
  isActive: boolean;
  isRTL: boolean;

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private coreService: CoreService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private navigationService: NavigationService,
  ) {}

  @HostBinding('class')
  get levelClass() {
    return `item-level-${this.level}`;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter(() => this.isDropdown(this.item)),
        untilDestroyed(this),
      )
      .subscribe(() => this.onRouteChange());

    this.navigationService.openChange$
      .pipe(
        filter(() => this.isDropdown(this.item)),
        untilDestroyed(this),
      )
      .subscribe((item) => this.onOpenChange(item));
    this.isRTL = GetLanguage() === 'ar';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.hasOwnProperty('item') &&
      this.isDropdown(this.item)
    ) {
      this.onRouteChange();
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter((child) => this.isDropdown(child))
      .some((child) => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some((child) => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }

  addToFast() {
    this.coreService
      .getRequest(`admin/quick-list`)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        const data = res.data;
        if (data?.length < 5) {
          const body = new FormData();
          body.append('link', this.item['route'] || '');
          body.append('title', this.item.label || '');
          body.append('icon', this.item['icon'] || '');
          this.coreService
            .postRequest(`admin/create-quick-list`, body)
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
              const obj = {
                title: this.item.label,
                link: this.item['route'] || '',
                icon: this.item['icon'],
              };
              data.push(obj);
              this.configService.quickList$.next(data);
              this.toastr.success(res?.message);
            });
        } else {
          this.toastr.error(
            this.translateService.instant(
              'Sorry, You can not add more than 5 items to the quick list',
            ),
          );
        }
      });
  }
}
