import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { GetCurrentUser } from '../shared/functions/shared-functions';

export interface Message {
  source: string;
  content: string;
}

@Injectable()
export class SocketIo {
  io: Socket;

  constructor(private socket: Socket) {
    this.io = socket.connect();
  }

  // listen event
  onFetchAdminMessages() {
    return this.socket.fromEvent(
      `private-send-admin-message-${GetCurrentUser()?.unique_id}`,
    );
  }

  // listen event
  onFetchMessages() {
    return this.socket.fromEvent(
      `private-send-message-${GetCurrentUser()?.unique_id}`,
    );
  }

  // listen event
  onFetchCourseMessages() {
    return this.socket.fromEvent(`private-new-course-message`);
  }
}
