import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { getCurrentUserRoles } from '../../shared/functions/shared-functions';

@Component({
  selector: 'app-grouped-cashier',
  templateUrl: './grouped-cashier.component.html',
  styleUrls: ['./grouped-cashier.component.scss'],
})
export class GroupedCashierComponent implements OnInit {
  form: UntypedFormGroup;
  globalFilter = new Subject();
  roles = getCurrentUserRoles();
  constructor(private fb: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      civiLID: new UntypedFormControl(null, []),
      passportNo: new UntypedFormControl(null, []),
      fileNo: new UntypedFormControl(null, []),
    });
  }
  search() {
    console.log(' this.form ', this.form.value);
    this.globalFilter.next(this.form.value);
  }
}
