<div class="container-fluid mx-0 p-0 grouped-cashier">
  <form class="p-4" [formGroup]="form">
    <div class="flex flex-col sm:flex-row sm:gap-4">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Civil number' | translate }}</mat-label>
        <input
          type="number"
          matInput
          placeholder="{{ 'Civil number' | translate }}"
          formControlName="civiLID"
        />
      </mat-form-field>
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Passport number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Passport number' | translate }}"
          formControlName="passportNo"
        />
      </mat-form-field>
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'File number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'File number' | translate }}"
          formControlName="fileNo"
        />
      </mat-form-field>
      <div class="flex-1 w-100">
        <div align="start" class="py-2 px-2">
          <button
            color="primary"
            mat-flat-button
            type="submit"
            (click)="search()"
          >
            {{ 'Search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div
    class="row mx-0"
    *ngIf="
      roles?.includes('BoShowAllCashierEng') ||
      roles?.includes('BoShowAllCashierClassification')
    "
  >
    <div class="col-sm-12 mx-0 p-0">
      <mat-tab-group [backgroundColor]="'primary'">
        <!--        <mat-tab>-->
        <!--          <ng-template mat-tab-label>{{-->
        <!--            "Professions center" | translate-->
        <!--            }}</ng-template>-->
        <!--          <div class="mt-3 mx-0">-->
        <!--            <cashier-list [isGroupedCashier]="true" [globalFilter]="globalFilter"></cashier-list>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <mat-tab *ngIf="roles?.includes('BoShowAllCashierEng')">
          <ng-template mat-tab-label>{{ 'Engineers' | translate }}</ng-template>
          <div class="mt-3 mx-0">
            <cashier-list
              [listType]="'engineers'"
              [pageCode]="'ReCaPosXAllEng'"
              [isGroupedCashier]="true"
              [globalFilter]="globalFilter"
            ></cashier-list>
          </div>
        </mat-tab>
<!--        <mat-tab *ngIf="roles?.includes('BoShowAllCashierEng')">-->
<!--          <ng-template mat-tab-label>{{ 'Kuwaitis' | translate }}</ng-template>-->
<!--          <div class="mt-3 mx-0">-->
<!--            <cashier-list-->
<!--              [isGroupedCashier]="true"-->
<!--              [listType]="'kuwaitis'"-->
<!--              [pageCode]="'CaPosX2'"-->
<!--              [globalFilter]="globalFilter"-->
<!--            >-->
<!--            </cashier-list>-->
<!--          </div>-->
<!--        </mat-tab>-->
        <mat-tab *ngIf="roles?.includes('BoShowAllCashierClassification')">
          <ng-template mat-tab-label>{{
            'Classification' | translate
          }}</ng-template>
          <div class="mt-3 mx-0">
            <cashier-list
              [listType]="'classification'"
              [pageCode]="'ReCaPosXAllClass'"
              [isGroupedCashier]="true"
              [globalFilter]="globalFilter"
            ></cashier-list>
          </div>
        </mat-tab>
        <!--        <mat-tab>-->
        <!--          <ng-template mat-tab-label>{{-->
        <!--            "Add training" | translate-->
        <!--            }}</ng-template>-->
        <!--          <div class="mt-3 mx-0">-->
        <!--            <cashier-list [isGroupedCashier]="true" [globalFilter]="globalFilter"></cashier-list>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <!--        <mat-tab>-->
        <!--          <ng-template mat-tab-label>{{-->
        <!--            "Accreditation center" | translate-->
        <!--            }}</ng-template>-->
        <!--          <div class="mt-3 mx-0">-->
        <!--            <cashier-list [isGroupedCashier]="true" [globalFilter]="globalFilter"></cashier-list>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <!--        <mat-tab>-->
        <!--          <ng-template mat-tab-label>{{ "Support" | translate }}</ng-template>-->
        <!--          <div class="mt-3 mx-0">-->
        <!--            <cashier-list [isGroupedCashier]="true" [globalFilter]="globalFilter"></cashier-list>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <!--        <mat-tab>-->
        <!--          <ng-template mat-tab-label>{{ "Others" | translate }}</ng-template>-->
        <!--          <div class="mt-3 mx-0">-->
        <!--            <cashier-list [isGroupedCashier]="true" [globalFilter]="globalFilter"></cashier-list>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
      </mat-tab-group>
    </div>
  </div>
</div>
