import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { CoreService } from '../../../services/core.service';
import {
  ConfirmedValidator,
  GetCurrentUser,
  HandleResponseError,
} from '../../../shared/functions/shared-functions';
import { PageTitleService } from '../../../core/components/page-title/page-title.service';
import { fadeInUp400ms } from '../../../../@config/animations/fade-in-up.animation';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [fadeInUp400ms],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  mainLogo = environment.mainLogo;
  form: FormGroup;

  newInputType = 'password';
  oldInputType = 'password';
  confirmInputType = 'password';
  confirmVisible = false;
  newPassVisible = false;
  oldPassVisible = false;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public router: Router,
    private coreService: CoreService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public pageTitleService: PageTitleService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        newPass: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        oldPass: ['', [Validators.required]],
      },
      { validators: [ConfirmedValidator('newPass', 'confirmPassword')] },
    );
  }

  send(value) {
    // console.log('value', value);
    const body = {
      NewPassword: this.form.value.newPass,
      OldPassword: this.form.value.oldPass,
      UserId: GetCurrentUser()?.id,
      auditPageCode: 'BoShowUsersCreateAccount',
    };
    // // console.log(body)
    this.coreService.postRequest('User/ChangePassword', body).subscribe(
      (res) => {
        // // console.log('')res', res);
        this.form.reset();
        this.toastr.success(
          this.translate.instant('Password changed successfully!'),
        );
        setTimeout(() => {
          this.toastr.warning(
            this.translate.instant('System will logout in moments'),
          );
        }, 1000);
        setTimeout(() => {
          this.authService.logOut();
        }, 5000);
      },
      (error) => {
        // // console.log('')err', error)
        this.toastr.error(HandleResponseError(error));
      },
    );
  }

  toggleVisibility(type) {
    if (type === 'new') {
      if (this.newPassVisible) {
        this.newInputType = 'password';
        this.newPassVisible = false;
        this.cd.markForCheck();
      } else if (type === 'old') {
        this.newInputType = 'text';
        this.newPassVisible = true;
        this.cd.markForCheck();
      } else {
        this.newInputType = 'text';
        this.newPassVisible = true;
        this.cd.markForCheck();
      }
    } else if (type === 'old') {
      if (this.oldPassVisible) {
        this.oldInputType = 'password';
        this.oldPassVisible = false;
        this.cd.markForCheck();
      } else {
        this.oldInputType = 'text';
        this.oldPassVisible = true;
        this.cd.markForCheck();
      }
    } else {
      if (this.confirmVisible) {
        this.confirmInputType = 'password';
        this.confirmVisible = false;
        this.cd.markForCheck();
      } else {
        this.confirmInputType = 'text';
        this.confirmVisible = true;
        this.cd.markForCheck();
      }
    }
  }
}
