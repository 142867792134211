import { Component, Input, OnInit } from '@angular/core';
import { scaleIn400ms } from '../../../../@config/animations/scale-in.animation';
import { scaleFadeIn400ms } from '../../../../@config/animations/scale-fade-in.animation';
import { stagger40ms } from '../../../../@config/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../@config/animations/fade-in-up.animation';
import { fadeInRight400ms } from '../../../../@config/animations/fade-in-right.animation';
import { Router } from '@angular/router';
import { NavigationService } from '../../../../@config/services/navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms,
  ],
})
@UntilDestroy()
export class DataGridComponent implements OnInit {
  @Input() data;
  @Input() label: string;
  editRoute: any;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.navigationService.urlAfterRedirectsObservable$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.editRoute = res.split('list')[0];
      });
  }

  openRow(row?) {
    this.router.navigate([`${this.editRoute}${row.id}`], {});
  }
}
