<page-container [hasFilter]="false" [listName]="''" [hasGrid]="false">
  <form
    [formGroup]="form"
    (ngSubmit)="collect()"
    autocomplete="off"
    class="form-sticky-title"
  >
    <div class="card flex-auto">
      <mat-divider class="-mx-6 text-border"></mat-divider>
      <div class="px-6 py-4 flex flex-col">
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input
              class="disabled-mat-form-input"
              readonly
              matInput
              placeholder="{{ 'Name' | translate }}"
              value="{{ name }}"
              [disabled]="true"
            />
          </mat-form-field>

          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Civil Id' | translate }}</mat-label>
            <input
              class="disabled-mat-form-input"
              readonly
              matInput
              placeholder="{{ 'Civil Id' | translate }}"
              value="{{ collectionData?.civilID }}"
              [disabled]="true"
            />
          </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'collected service name' | translate }}</mat-label>
            <input
              type="text"
              readonly
              matInput
              placeholder="{{ 'collected service name' | translate }}"
              formControlName="collected_service"
            />
          </mat-form-field>

          <mat-form-field
            class="w-50 w-sm-100"
            appearance="fill"
            *ngIf="form.get('organization')?.value"
          >
            <mat-label>{{ 'Organization' | translate }}</mat-label>
            <input
              type="text"
              readonly
              matInput
              placeholder="{{ 'Organization' | translate }}"
              formControlName="organization"
            />
          </mat-form-field>
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Passport number' | translate }}</mat-label>
            <input
              class="disabled-mat-form-input"
              readonly
              matInput
              placeholder="{{ 'Passport number' | translate }}"
              value="{{ collectionData?.passportNum }}"
              [disabled]="true"
            />
          </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Price' | translate }}</mat-label>
            <input
              class="disabled-mat-form-input-price"
              readonly
              matInput
              placeholder="{{ 'Price' | translate }}"
              [value]="totalPrice"
              [disabled]="true"
            />
          </mat-form-field>
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Value after discount' | translate }}</mat-label>
            <input
              class="disabled-mat-form-input-price"
              readonly
              matInput
              placeholder="{{ 'Value after discount' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="priceAfterDiscount"
              [disabled]="true"
            />
          </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Discount' | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'Discount' | translate }}"
              formControlName="discount"
              (ngModelChange)="changeDiscount()"
            >
              <mat-option
                *ngFor="let discount of discountTypes"
                [value]="discount"
              >
                {{ discount?.name | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field
            class="w-50 w-sm-100"
            appearance="fill"
            *ngIf="showNumOfYears"
          >
            <mat-label>{{ 'Renewal num of years' | translate }}</mat-label>
            <input
              type="number"
              [min]="min"
              matInput
              placeholder="{{ 'Renewal num of years' | translate }}"
              formControlName="numOfYears"
              (input)="changeNumOfYears($event)"
            />
            <mat-error
              *ngIf="
                form?.controls['numOfYears'].hasError('min') &&
                form?.controls['numOfYears'].touched
              "
              class="warn-text gene-block"
            >
              {{
                'Renewal number of years must not be lower that current year'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field
            class="w-50 w-sm-100"
            appearance="fill"
            *ngIf="showMemberShipInfo && !collectionData.putAction"
          >
            <mat-label>{{ 'Last renew date' | translate }}</mat-label>
            <input
              type="text"
              class="disabled-mat-form-input"
              matInput
              placeholder="{{ 'Last renew date' | translate }}"
              [value]="collectionData?.personMembership?.expiryDate"
              [disabled]="true"
            />
          </mat-form-field>

          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Expiry date' | translate }}</mat-label>
            <input
              type="text"
              class="disabled-mat-form-input"
              matInput
              placeholder="{{ 'Expiry date' | translate }}"
              [value]="collectionData?.personMembership?.expiryDateAfterYears"
              [disabled]="true"
            />
          </mat-form-field>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <div class="px-6 page-title py-2 flex items-center">
          <h4 class="title m-0">{{ 'Payment method' | translate }}</h4>
        </div>

        <div
          *ngIf="roles.includes('GetAllPaymentMethod')"
          class="flex flex-col sm:flex-row sm:gap-4"
        >
          <mat-form-field class="w-50 w-sm-100" appearance="fill">
            <mat-label>{{ 'Payment method' | translate }}:</mat-label>
            <mat-select
              placeholder="{{ 'Payment method' | translate }}"
              formControlName="payment"
              (ngModelChange)="changePayment()"
            >
              <mat-option
                *ngFor="let option of paymentMethods"
                [value]="option"
              >
                {{ option?.name | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="w-50 w-sm-100">
            <mat-form-field
              class="w-50 w-sm-100"
              *ngIf="
                selectedPayment?.isNeedReferenceNumber &&
                !collectionData.from_service_request
              "
              appearance="fill"
            >
              <mat-label>{{ 'Reference number' | translate }}</mat-label>
              <input
                matInput
                placeholder="{{ 'Enter reference number' | translate }}"
                formControlName="refNum"
              />
            </mat-form-field>
          </div>
        </div>

        <div align="end" class="px-4 py-2">
          <button
            mat-flat-button
            type="submit"
            [disabled]="form.invalid || loadBtn"
            color="primary"
          >
            <span
              *ngIf="
                !collectionData?.putAction &&
                !collectionData?.from_service_request
              "
              class="mat-button-wrapper"
              >{{ 'Pay & print receipt' | translate }}</span
            >
            <span
              *ngIf="
                collectionData?.putAction &&
                !collectionData?.from_service_request
              "
              class="mat-button-wrapper"
              >{{ 'Edit & print receipt' | translate }}</span
            >
            <span
              *ngIf="collectionData?.from_service_request"
              class="mat-button-wrapper"
              >{{ 'Edit' | translate }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </form>
</page-container>
