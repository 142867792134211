<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar w-full flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.d-flex]="mobileQuery"
    [class.hidden]="!mobileQuery"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>
  <app-breadcrumbs *ngIf="!mobileQuery"></app-breadcrumbs>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block flex items-center"
  >
    <img alt="Logo" class="w-8 select-none" src="" />
    <!--    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">Kuwait Society of Engineers</h1>-->
  </a>

  <div class="recent d-flex">
    <a
      *ngFor="let feature of features"
      class="text-dark mt-2 py-2 cursor-pointer text-center lg:mx-3 quick-access-item position-relative hover:bg-primary/5 hover:text-primary trans-ease-out rounded block no-underline"
    >
      <mat-icon
        svgIcon="mat:delete"
        (click)="removeQuickAccess($event, feature)"
        class="icon-sm position-absolute remove-icon"
        color="primary"
      ></mat-icon>
      <div class="m-0 p-0" [routerLink]="feature?.link">
        <mat-icon
          [svgIcon]="feature.icon"
          class="icon-l mx-4"
          color="primary"
        ></mat-icon>
        <div class="body-2 hidden sm:block">
          {{ feature.title | translate }}
        </div>
      </div>
    </a>
  </div>
  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center"
  >
    <app-navigation-item
      *ngFor="let item of navigationItems"
      [item]="item"
    ></app-navigation-item>
  </div>

  <span class="flex-1"></span>
  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <!--      <app-toolbar-notifications></app-toolbar-notifications>-->
      <span
        tooltip="{{ 'Number of operations' | translate }}"
        class="me-4 count-info"
        >{{ cashCount }}</span
      >
      <span tooltip="{{ 'Number of files' | translate }}" class="count-info">{{
        personstatusCount
      }}</span>
    </div>

    <!--    <div-->
    <!--      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300 search-box">-->
    <!--      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>-->
    <!--      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"-->
    <!--             placeholder="{{'Search...' | translate}}" [matAutocomplete]="auto" type="search">-->
    <!--    </div>-->

    <mat-autocomplete
      class="search-menu"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <mat-option
        class="search-menu-item"
        [routerLink]="item?.route"
        *ngFor="let item of searched_menu_items"
      >
        <mat-icon
          svgIcon="{{ item?.icon ? item?.icon : 'mat:link' }}"
          class="rtl:ml-2 ltr:mr-2"
        ></mat-icon>
        <span>{{ item?.translated_label }}</span>
      </mat-option>
    </mat-autocomplete>
    <div class="align-items-center d-flex gap-3 px-1">
      <img
        *ngIf="countryDetails"
        class="country-flag"
        tooltip="{{ nationality }}"
        src="{{ countryDetails?.flags.png }}"
      />
      <button
        class="d-flex align-items-start justify-content-center"
        [matMenuTriggerFor]="languageMenu"
        mat-icon-button
        type="button"
      >
        <!--        <mat-icon svgIcon="{{selectedLanguage?.flag}}"></mat-icon>-->
        <a
          class="align-items-center d-flex h-100 justify-content-center language"
          >{{ selectedLanguage?.label | translate }}</a
        >
      </button>
    </div>

    <div *ngIf="userVisible$ | async" class="px-1">
      <app-toolbar-user></app-toolbar-user>
    </div>

    <mat-menu
      #languageMenu="matMenu"
      overlapTrigger="false"
      xPosition="before"
      yPosition="below"
    >
      <ng-container *ngFor="let language of languageList">
        <button mat-menu-item (click)="changeSiteLanguage(language.code)">
          <!--          <mat-icon svgIcon="{{language.flag}}"></mat-icon>-->
          <span> {{ language.label | translate }} </span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>

<app-navigation
  *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"
></app-navigation>
