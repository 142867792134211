<!--<div class="spinner-border loader" [ngClass]="{'has-text' : loadText}" role="status" *ngIf="isPageLoading || isXHRLoading" >-->
<!--  <span class="loading__anim" id=""></span>-->
<!--</div>-->

<div
  class="dotted-loader loader"
  [ngClass]="{ 'has-text': loadText }"
  role="status"
  *ngIf="isPageLoading || isXHRLoading"
></div>
<div
  class="loadTextContainer"
  *ngIf="isPageLoading || (isXHRLoading && loadText)"
>
  <span class="loadText">{{ loadText }}</span>
</div>
