import { Component, Input } from '@angular/core';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent {
  @Input() selectedImage;

  constructor(public globalService: GlobalService) {}
  closeImageViewer() {
    this.globalService.imageViewer.next('');
  }
}
