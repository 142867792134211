export const environment = {
  production: true,
  apiUrl: 'https://api.kseonline.org/api/',
  webUrl: 'https://eng.kseonline.org/',
  classification_link: 'https://classification.kseonline.org/',
  captchaSiteKey: '',
  captchaSecretKey: '',
  secret_key: 'kse01069123456987',
  mainLogo_arabic: 'assets/img/logos/arabic-logo.jpeg',
  mainLogo_english: 'assets/img/logos/english-logo.jpeg',
  mainLogo_arabic_white: 'assets/img/logos/logo3_white_new.png',
  mainLogo_english_white: 'assets/img/logos/logo3_white_new.png',
  small_logo: 'assets/img/logos/small_orginal_logo.png',
  mainLogo: 'assets/img/logos/KSELogo.png',
  headerLogo: 'assets/img/logos/KSELogo.png',
  secondaryLogo: 'assets/img/logos/KSELogo.png',
  favicon: 'assets/img/favicon.ico',
  scanFileUrl: 'https://scan.kseonline.org/API/filescanner',
  scanFilePassword: 'PEtER@W@Geeh.F@YeLCKann2r',
  supportPhone: '',
  socketUrl: '',
  supportEmail: '',
  currency: 'SR',
  minAge: 18,
  idle_time_in_seconds: 1800,
  time_before_logout_in_seconds: 30,
  encryptedResponse: true,
};
