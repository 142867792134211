<div
  class="progress-message d-flex flex-column align-items-start cursor-wait"
  *ngIf="loading || progress"
>
  <div class="msg-content">
    <span class="message">{{ message || 'Loading' | translate }}</span>
    <span class="progress_loader" *ngIf="!progress && progress !== 0"></span>
  </div>
  <div
    class="progress-percentage mt-3"
    *ngIf="progress || progress === 0"
    [style.width]="progress + '%'"
  >
    {{ progress }}%
  </div>
</div>
